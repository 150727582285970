
.incoming-order__card {
    height: 100%;
    background-color: #f3f3f3;
    min-height: calc(100vh - 250px);

    .card-header {
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1px;
        padding: 15px 20px;

        &.is--red {
            background-color: #db5b5b;
        }

        &.is--orange {
            background-color: #ecb34c;
        }

        &.is--green {
            background-color: #5ab571;
        }

        .card-header__title {
            margin-bottom: 0;
        }

        .card-header__count {
            margin-bottom: 0;
            margin-left: 10px;
        }

        .card-header__icon {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    .card-body {
        padding: 20px;

        .card-body__title {
            font-size: 14px;
            color: #a2a2a2;
            font-weight: $font-weight-bold;
            letter-spacing: 0.3px;

            &.has--border-top {
                padding-top: 20px;
                border-top: 2px solid #c8c8c8;
            }
        }

        .card-body__item {
            background-color: $white;
            border-radius: 2px;
            box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);
            padding: 20px;
            margin-bottom: 10px;
            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            .item-time {
                margin-bottom: 0;
                font-size: 14px;
                color: #a6a6a6;
                font-weight: 500;
            }

            .item-badge {
                font-size: 11px;
                color: white;
                background-color: #ecb34c;
                border-radius: 30px;
                padding: 2px 15px;
                text-transform: uppercase;
                align-items: center;
                letter-spacing: 0.20px;
                font-weight: 500;
            }

            .item-name-wrapper {
                .item-name {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: $font-weight-bold;
                    color: $dark;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 200px;
                }

                .item-price {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: $font-weight-bold;
                    color: $dark;
                }
            }

            .item-order-number {
                margin-bottom: 0;
                font-size: 14px;
                color: $dark;
            }

            .item-order-product {
                margin-bottom: 0;
                font-size: 14px;
                color: $dark;
            }
        }
    }
}
