.company-slider {
  height: 600px;
  @include media-breakpoint-down(xl) {
    height: 450px;
  }



  &__slides-wrapper {
    height: 600px;
    @include media-breakpoint-down(xl) {
      height: 450px;
    }
  }

  &__image {
    min-width: 100%;
    height: 600px;
    position: relative;
    object-fit: cover;
    @include media-breakpoint-down(xl) {
      height: 450px;
    }

    &::before {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.56);
    }
  }

  &__item {
    .time-badge {
      position: absolute;
      top: 20px;
      right: 0;
    }
  }

  &__information-wrapper {
    position: absolute;
    bottom: 50px;
    left: 40px;
    @include media-breakpoint-down(md) {
      bottom: 30px;
      left: 20px;
    }
  }

  &__title {
    color: white;
    font-weight: bold;
    font-size: 26px;
    line-height: 15px;
    margin-bottom: 9px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
  }

  &__sub-title {
    color: $white;
    margin-bottom: 25px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  .slider-navigation-wrapper {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 10;

    @include media-breakpoint-down(md) {
      bottom: 30px;
      right: 20px;
    }
  }

  .carousel-caption {
    position: relative;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: left;
    padding-top: 0;
    padding-bottom: 0;
  }
}