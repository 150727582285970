.timeline {
  height: 900px;
  @include media-breakpoint-down(sm) {
    height: 700px;
  }

  @include media-breakpoint-up(md) {
    // overlay shadow left
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 240px;
      width: 200px;
      z-index: 10;
      background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,0) 70%);
    }

    // overlay shadow right
    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      height: 240px;
      width: 200px;
      z-index: 10;
      background: linear-gradient(90deg, rgba(247,247,247,0) 0%, rgba(247,247,247,1) 70%);
    }
  }

  &__section {
    .bold-text-parallax {
      font-size: 15vw;
      color: #e7e7e7;
      line-height: 1em;
      margin-top: 3vw;
    }

    .outline-text {
      left: 0;
      font-size: 10vw;
      top: 22vw;
      right: 0;
      text-align: center;
      color: #e7e7e7;
      line-height: 1em;
    }
  }

  &__content-popup {
    display: block;
    position: absolute;
    bottom: 180px;
    width: 400px;
    height: auto;
    background: white;
    margin-left: calc(50% - 200px);
    box-shadow: 0 2px 18px 14px rgba(0,0,0,0.05);
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    @include media-breakpoint-down(sm) {
      width: 90vw;
      margin-left: -27vw;
      bottom: 140px;
    }

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 10px 0 10px;
      border-color: $white transparent transparent transparent;
      left: calc(50% - 10px);
      position: absolute;
    }

    strong {
      display: block;
      font-size: 17px;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  &__wrap {
    padding-top: 560px;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    left: 227px;
    @include media-breakpoint-down(xl) {
      max-width: 700px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 400px;
    }
  }

  &__item {
    cursor: pointer;

    &--bottom {
      &:after {
        display: none;
      }
    }

    &:hover {
      .timeline-entry__year {
        color: $primary;
      }
    }

    &.is--active {
      .timeline__content-popup {
        display: block;
      }

      .timeline-entry__year {
        color: $primary;

        &:after {
          height: 90px;
          background: $primary;
          @include media-breakpoint-down(sm) {
            height: 50px;
          }
        }
      }
    }
  }

  &-nav {
    &-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $primary;
      cursor: pointer;
      z-index: 100;
      color: $primary;
      height: 200px;
      width: 200px;
      background: transparent;
      border-radius: 100%;
      top: auto !important;
      font: normal normal normal 14px/1 Font Awesome 5 Free;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: 0.2s ease-in-out;
      @include media-breakpoint-down(xl) {
        height: 150px;
        width: 150px;
      }
      @include media-breakpoint-down(sm) {
        height: 50px;
        width: 50px;
      }

      &:hover {
        background: $primary;
        color: $white;
      }

      &.timeline-nav-button--next {
        right: -30px;
        bottom: 50px;
        @include media-breakpoint-down(xl) {
          bottom: 120px;
        }
        @include media-breakpoint-down(sm) {
          bottom: 0;
          right: calc(50% - 60px);
        }

        &:before {
          font-size: 30px;
          content: '\f054';
          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }
      }

      &.timeline-nav-button--prev {
        left: -30px;
        bottom: 50px;
        @include media-breakpoint-down(xl) {
          bottom: 120px;
        }
        @include media-breakpoint-down(sm) {
          bottom: 0;
          left: calc(50% - 70px);
        }

        &:before {
          font-size: 30px;
          content: '\f053';
          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }
      }
    }
  }

  &-entry__year {
    color: #a2a2a2;
    font-size: 15px;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    letter-spacing: 2px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 50px;
    transition: 0.2s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      bottom: 40px;
      height: 20px;
      width: 1px;
      background: transparentize(#a2a2a2, 0.5);
      right : 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 40px;
      height: 50px;
      width: 1px;
      background: #b9b9b9;
      left: 50%;
      transition: 0.2s ease-in;
      @include media-breakpoint-down(sm) {
        height: 30px;
      }
    }
  }

  &__content {
    &-popup {
      display: none;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
