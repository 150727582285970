.vue2leaflet-map {
    position: relative;
    z-index: 1;
    min-height: 300px;
}

.leaflet-marker-icon {
    display: flex;
    justify-content: space-between;
}

.map {
    width: 50%;
    height: 100vh;
    z-index: 1;
    position: fixed;
    left: 0;
    @include media-breakpoint-up(md) {
        top: 60px;
    }
    @include media-breakpoint-down(md) {
        position: relative;
        width: 100%;
        height: 40vh;
        margin-bottom: 30px;
        padding-top: 70px;
        padding-left: 15px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 60px;
    }

    .vue2leaflet-map {
        min-height: 100%;
    }
}

.map-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
    position: fixed;

    @include media-breakpoint-down(md) {
        position: absolute;
        width: 100%;
        height: 40vh;
    }
}

.map-badge {
    border: 2px solid #ffffff;
    height: 60px;
    width: 60px;
    border-radius: 40px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        font-family: $font-family-icon;
        font-size: 26px;
        color: $white;
    }

    &.is--bakery {
        background-color: $primary;
        i:before {
            color: $white;
        }
    }

    &.is--cafe {
        background-color: $secondary;
        i:before {
            color: $white;
        }
    }

    &.is--bakery + .is--cafe {
        margin-left: -10px;
    }

    &.is--disabled {
        background: $white;
        border: 2px solid #e2e2e2;

        &:after {
            content: "\f00d";
            font-family: $font-family-icon;
            font-size: 32px;
            color: #e2e2e2;
        }
    }
}

.map-badge__card {
    left: 0;
    position: relative;
    user-select: none;
    max-width: 250px;


    .card-img-top {
        height: 130px;
        object-fit: cover;
    }

    .card-body {
        padding: 20px 0;

        .map-card__title {
            font-size: 14px;
            text-transform: uppercase;
            color: $dark;
            font-weight: $font-weight-bold;
            letter-spacing: 0.60px;
            margin-bottom: 0;
        }

        .map-card__sub-title {
            font-size: 12px;
            color: $dark;
        }

        .map-card__opening {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.42px;
            font-weight: $font-weight-bold;
            margin-bottom: 0;

            &.is--open {
                color: #5ab571;
            }

            &.is--closed {
                color: #db5b5b;
            }

            &.is--closed-shortly {
                color: #ecb34c;
            }
        }

        .map-card__open-time span {
            font-size: 12px;
            font-weight: 500;
        }

        .map-card__open-time {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.leaflet-popup-content-wrapper {
    background: transparent;
    box-shadow: none;
}

.leaflet-popup-content {
    margin: 0;
}

.leaflet-popup-tip-container {
    display: none;
}

.leaflet-container {
    font: inherit;

    .leaflet-popup-close-button {
        display: none;
    }
}