.order-card-item {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: 0.5px solid #7d7d7d;
  }

  .item-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    margin-right: 10px;

    @include media-breakpoint-down(md) {
      width: 60px;
    }
  }

  .item-description {
    font-size: 16px;
    font-weight: $font-weight-bold;
    color: $dark;
  }

  .item-number {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: $dark !important;
    letter-spacing: normal !important;

    .product-delete {
      color: #b3b3b3;
      font-size: 13px;
      cursor: pointer;

      &:before {
        content: "\58";
        color: #b3b3b3;
        font-size: 13px;
        font-family: $font-family-icon;
        margin-right: 5px;
      }
    }
  }

  .item-price {
    color: #b9b9b9 !important;
    font-weight: 500 !important;
    font-size: 15px !important;
  }

  .item-quantity {
    color: #b9b9b9;
    font-weight: 500;
    font-size: 15px;
  }

  .item-total-price {
    color: $dark;
    font-weight: 500;
    font-size: 15px;
  }
}

.order-card-result {
  padding: 20px 60px;
  @include media-breakpoint-down(md) {
    padding: 20px 30px;
  }

  .sub-total > p {
    margin-bottom: 0;
  }

  .sub-total {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .order-card-vat {
    font-size: 13px;
  }

  .total-price {
    .total-price__text {
      font-weight: 500;
      font-size: 13px;
    }

    .total-price__sum {
      font-size: 21px;
      font-weight: 500;
      color: $dark;
    }
  }
}