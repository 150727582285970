.sidebar-close {
  top: 30px;
  right: 30px;
  padding: 10px;
  display: flex;
  align-items: center;

  .sidebar-close__text {
    font-size: 12px;
    font-weight: $font-weight-bold;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: $dark;
  }

  .sidebar-close__icon {
    font-size: 26px;
  }
}