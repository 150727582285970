.order-detail-card {
  background-color: white;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);

  .order-detail-card__header {
    .header-col {
      padding:20px 10px;
      &.has--light-grey-background {
        background-color: #909090;
      }
      &.has--dark-grey-background {
        background-color: #828282;
      }
      .header-title {
        text-transform: uppercase;
        color: white;
        letter-spacing: 2px;
        font-weight: $font-weight-bold;
        font-size: 12px;
      }

      .header-item-number {
        font-size: 14px;
        color: white;
      }

      .header-date {
        font-size: 14px;
        color: white;
      }
    }
  }

  .order-detail-card__body {
    .body-col {
      padding: 60px 60px 0 60px;

      @include media-breakpoint-down(md) {
        padding: 20px 30px;
      }

    }
    .body-title {
      font-size: 12px;
      color: #a4a4a4;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    .card-address > p {
      margin-bottom: 0;
      line-height: 1.57;
    }
  }
}