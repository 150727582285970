.product-filter {
  flex: 0 0 370px;
  background-color: #f8f8f8;
  position: relative;

  &:after {
    background: url("../../images/divider-sidebar.png") left top no-repeat;
    background-size: cover;
    bottom: 0;
    content: "";
    position: absolute;
    right: -27px;
    top: 0;
    width: 28px;

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  @include media-breakpoint-up(xxl) {
    flex: 0 0 402px;
    background-position: 390px 0;
  }

  @include media-breakpoint-down(lg) {
    width: 370px;
    height: 100%;
    position: fixed;
    z-index: 1050;
    overflow-y: scroll;
    top: 60px;
    left: 0;
    transform: translateX(100vw);
    background-image: none;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;

    &.is--open {
      .current-filters {
        display: none;
      }
    }
  }

  &__title {
    color: #4b4b4b;
    font-size: 14px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    margin: 0;
  }

  &__clear {
    background: none;
    font-size: 12px;
    color: #4b4b4b;
    border: none;
    @include media-breakpoint-down(lg) {
      border-bottom: 1px solid $dark;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__toggle {
    padding: 10px;
    border: 0;
    line-height: 1em;
    margin: 0;
    font-size: 12px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: #4b4b4b;
    background-color: $white;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease;

    @include media-breakpoint-down(md) {
      margin-right: 10px;
    }
    @include media-breakpoint-down(sm) {
      height: 37px;
    }

    i {
      transition: all 300ms ease;
    }

    &:hover {
      background: $primary;
      color: $white;
    }

    &:focus {
      outline: none;
    }

    .toggle-angle-down {
      font-size: 6px;
    }

    &.is--visible {
      i {
        transform: rotate(180deg);
      }
    }
  }

  &__options-toggle {
    font-size: 6px;
    color: #b6b6b6;
    width: 25px;
    margin-right: -5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
  }

  @include media-breakpoint-down(lg) {
    &__outer {
      justify-content: space-between;
      width: 100%;

      .product-filter__toggle {
        width: auto;
        border: 1px solid;
        border-radius: 17px;
        padding: 10px 20px;
        box-shadow: none;

        .bk-i-filter {
          margin-right: 5px;
        }
      }
    }
  }

  &__item {
    padding: 30px 42px 35px;
    position: relative;

    &:after {
      background: #ffffff;
      bottom: 0;
      content: "";
      height: 5px;
      left: 0;
      position: absolute;
      right: -27px;
      z-index: 1;
    }

    @include media-breakpoint-down(lg) {
      padding: 30px 20px;

      &:after {
        display: none;
      }

      &.current-filters {
        .product-filter__options-wrapper {
          padding: 0;
        }
      }
    }

    &.has--no-border {
      border-bottom: none;
    }

    &.has--background {
      background-color: #ededed;
    }

    &:last-child {
      border-bottom: none;
    }

    .close-filter {
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;

      .icon-close-filter {
        font-size: 20px;
      }
    }

    .product-filter__options {
      height: 100%;
      margin: 0 -10px;
      padding: 0 10px;
    }

    .product-filter__option-item {
      background-color: white;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      min-height: 45px;
      padding: 6px 20px;
      border-radius: 30px;
      border: 1px solid $white;
      box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.06);
      transition: border 300ms ease;

      &:hover {
        border: 1px solid #4b4b4b;
      }

      .option-item__content {
        display: flex;
        width: 100%;

        .custom-checkbox,
        .custom-control-label {
          align-items: center;
          display: flex;
          width: 100%;
          cursor: pointer;
        }

        .custom-control-label {
          &:before {
            top: 2px;
          }

          &:after {
            top: 8px;
          }
        }

        .rating-stars {
          margin-left: 5px;

          .rating-stars__icon {
            color: #e7cb5e;
          }
        }
      }
    }

    .product-filter__options-wrapper {
      padding: 10px 0 0 0;
      //
      //&.has--more {
      //    min-height: 167px;
      //}
      //
      //&:not(.no-collapse) {
      //    display: block !important;
      //}

      //&.collapse:not(.show) {
      //    max-height: 167px;
      //    overflow: hidden;
      //}

      .product-filter__current-item {
        cursor: pointer;
        padding: 7px 18px 7px 18px;
        background-color: white;
        box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.06);
        margin-right: 10px;
        margin-top: 10px;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;

        //&::after {
        //  margin-left: 14px;
        //  border-radius: 10px;
        //  background-color: #ededed;
        //  content: "\f00d";
        //  font-family: $font-family-icon;
        //}
      }

      .option-item__count {
        color: #b6b6b6;
        font-style: italic;
        font-size: 14px;
      }
    }

    &.has--background-dark {
      background-color: #ededed;
    }

    &.is--last-child {
      border-bottom: none;
    }

    &.is--open {
      .product-filter__toggle {
        .toggle-angle-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  .current-filters {
    padding: 43px 42px;
  }

  &.is--open {
    transform: translateX(0);
  }

  &__options-search {
    margin-bottom: 10px;
    margin-top: 20px;

    input {
      appearance: none;
      background: none;
      padding: 0 20px;
      border-radius: 30px;
      border: 1px solid #e8e8e8;
      color: #989898;
      line-height: 45px;
      font-size: 14px;
      width: 100%;
    }

    + .product-filter__options-wrapper {
      padding-top: 0;
    }
  }
}
