.product-category-tabs {
    @include media-breakpoint-down(sm) {
        padding-bottom: 75px;

        .slider-navigation-arrow {
            top: auto;
            transform: translateY(0);

            &.is--left {
                bottom: 0;
                left: calc(50% - 60px);
            }

            &.is--right {
                bottom: 0;
                right: calc(50% - 60px);
            }
        }
    }
}