.customer-account {
  background-color: #fafafa;
  min-height: 100vh;

  .account-address-card {
    &__btn {
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: $black;

      &:hover {
          color: $primary;
        }
    }

    .bk-i {
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        color: $black;
      }
    }
  }

  .account-navigation-mobile {
    margin-bottom: 20px;

    .account-navigation-mobile__navbar {
      box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.05);

      .account-navigation-mobile__link {
        color: $dark;
        font-size: 14px;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        padding: 10px;

        .account-navigation-mobile__icon {
          color: $primary;
          font-size: 20px;
          margin-right: 20px;
        }
      }
    }

    .information-btn {
      height: 100%;
      width: 100%;
      background-color: #bdbdbd;
      display: flex;
      align-items: center;
      justify-content: center;

      .information-btn__badge {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        color: white;
        font-size: 12px;
        font-weight: $font-weight-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #65af6e;
        left: 35px;
        top: 6px;

        @include media-breakpoint-down(lg) {
          left: 64px;
          top: 6px;
        }

        @include media-breakpoint-down(md) {
          left: 35px;
          top: 6px;
        }
      }

      .information-btn__icon {
        font-size: 26px;
        color: white;
      }
    }
  }

  .account-information {
    .page-header {
      width: 100%;
      height: 30vh;
    }

    .account-information__container {
      padding: 50px 100px;

      @include media-breakpoint-down(lg) {
        padding: 50px 60px;
      }

      @include media-breakpoint-down(md) {
        padding: 20px;
      }

      .account-information__header {

        @include media-breakpoint-down(md) {
          padding: 20px 20px;
        }

        .header-text {
          font-size: 17px;
          letter-spacing: -0.1px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }
        }

        .header-name {
          font-size: 38px;
          letter-spacing: -0.6px;
          line-height: 20px;

          @include media-breakpoint-down(md) {
            font-size: 34px;
            line-height: 15px;
          }
        }
      }

      .account-card-header {
        height: 300px;
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.05);
        width: 100%;

        @include media-breakpoint-down(md) {
          height: 200px;
        }

        .account-card__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }


      .account-card {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.05);
        width: 100%;
        height: auto;


        .account-card__image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .account-card__header {
          padding: 15px 50px;
          color: white;
          background-color: #4b4b4b;

          @include media-breakpoint-down(md) {
            padding: 15px 20px;
          }

          .account-card__title {
            margin: 0;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.70px;
            text-transform: uppercase;
          }

          .account-card__show-all {
            color: white;
            font-size: 12px;
          }
        }

        .account-card__body {
          padding: 20px 50px;
          background-color: white;

          @include media-breakpoint-down(md) {
            padding: 15px 20px;
          }

          .account-card__item {
            padding-top: 20px;
            border-bottom: 2px dashed rgba(0, 0, 0, 0.13);

            &:last-child {
              border-bottom: none;
            }

            .item-time {
              color: #adadad;
              font-size: 14px;
              font-weight: 500;
            }

            .item-wrapper {
              padding-bottom: 20px;

              display: flex;
              align-items: center;


              .item-number {
                font-size: 14px;
                color: $dark;
                font-weight: $font-weight-bold;
              }

              .item-price {
                font-size: 14px;
                color: $dark;
                font-weight: 500;

              }

              .item-badge {
                height: 30px;
                width: 30px;
                border-radius: 30px;
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;

                .item-badge__icon {
                  color: white;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .header-banner {
        height: 300px;
      }

    }
  }

  .account-message-sidebar {
    flex: 0 0 400px;
    background-color: #f5f5f5;
    padding: 150px 40px;
    height: auto;
    z-index: 1000;

    @include media-breakpoint-down(lg) {
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      padding-top: 100px;
      transform: translateX(100%);


      &.is--open {
        transform: translateX(-100%);
      }

    }


    &:before {
      background-image: url("../../images/badge-gold.svg");
    }

    .message-sidebar__header {
      font-weight: $font-weight-bold;
      font-size: 14px;
      color: #8d8d8d;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      &:before {
        content: "\f035";
        font-size: 20px;
        color: #8d8d8d;
        margin-right: 10px;
        font-family: $font-family-icon;
      }
    }

    .message-sidebar__body {
      .message-sidebar__item {
        padding: 15px;
        background-color: white;
        border-radius: 100px;
        opacity: 0.4;
        display: flex;
        align-items: center;
        margin-top: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
        transition: 0.3s;

        &.is--active {
          opacity: initial;
        }

        &:hover {
          opacity: initial;
        }

        &:focus {
          opacity: initial;
        }

        .message-information {
          &.is--active {
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: #65af6e;
            top: -4px;
            left: 35px;
          }
        }

        .item-icon {
          font-size: 25px;
          color: $primary;
        }

        .item-text-wrapper {
          display: flex;
          align-items: start;
          flex-direction: column;

          .item-title {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: $dark;
            line-height: 15px;
          }

          .item-link {
            color: #c0c0c0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}


