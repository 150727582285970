.page-header {
  height: 410px;
  width: 100%;
  content: "";
  background-color: $gray-200;
  background-image: url("../../images/hero-slider.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    height: 30vh;
  }

  &.is--bigger {
    height: 640px;
    @include media-breakpoint-down(sm) {
      height: 410px;
    }
  }

  .page-header__title {
    position: absolute;
    left: 0;
    bottom: -33px;

    .outline-text {
      font-size: 60px;
      @include media-breakpoint-up(lg) {
        font-size: 170px;
      }
    }

    @include media-breakpoint-down(md) {
      bottom: -20px;
      left: -10px;
    }
  }

  .page-header__progress {
    position: absolute;
    right: 40px;
    bottom: -90px;
    width: 1px;
    height: 150px;
    background-color: $primary;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}