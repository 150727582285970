.product-detail-cart-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);
  width: 780px;

  @include media-breakpoint-down(md) {
    width: 350px;
  }


  .product-detail-cart__inner {
    padding: 40px;

    .close-wrapper {
      padding: 20px;
      top: 0;
      right: 0;

      .close-icon {
        font-size: 25px;
      }
    }

    .detail-cart-title {
      color: $dark;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        font-size: 22px;
        margin-bottom: 0;
      }
    }

    .detail-cart-product {
      border-bottom: 1px dashed #bcbcbc;
      padding-top: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      .product-image {
        height: 50px;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
          height: 30px;
        }
      }
      .product-title {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 14px;
      }
      .product-price {
        font-size: 14px;
        font-weight: 500;
      }
      .product-add {
        color: $dark;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        &:after {
          content: "\f061";
          color: $dark;
          font-size: 8px;
          font-family: $font-family-icon;
          margin-left: 5px;
        }
      }
    }
  }
}