.modal {

    .modal-dialog {
        max-width: 850px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 100%;

        .modal-content {
            border: none;
            border-radius: 2px;
            box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);


            .modal-header {
                border-bottom: none;
                padding: 50px 50px;

                &.has--divider-bottom:after {
                    left: 0;
                    bottom: -25px;
                }

                .modal-close-wrapper {
                    padding: 20px;
                    right: 0;
                    top: 0;
                    cursor: pointer;

                    .close-text {
                        font-size: 13px;
                        text-transform: uppercase;
                        color: $dark;
                        font-weight: 500;
                        letter-spacing: 1.3px;
                    }

                    .close-icon {
                        font-size: 25px;
                        color: $dark;
                    }
                }
            }

            .modal-body {
                padding: 50px 50px;
            }

            .modal-footer {
                border-top: none;
                padding: 0 50px 50px 50px;
            }
        }
    }
}