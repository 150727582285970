.progress-bar {
  height: 300px;
  width: 1px;
  background-color: $primary;
  z-index: 10;
  position: relative;
  @include media-breakpoint-down(md) {
    height: 270px;
  }

  &__text {
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    transform: rotate(270deg);
    text-transform: uppercase;
    bottom: -68px;
    left: -73px;
    z-index: 10;
    letter-spacing: 2px;
  }
}

.progress-price-bar {
  background-color: #eeeeee;
  border-radius: 30px;
  height: 15px;
  @include media-breakpoint-down(md) {
    height: 12px;
  }

  &__rail {
    background-color: $primary;
    width: 60%;
    height: 100%;
    border-radius: 30px;

    &.is--full {
      width: 100%;
      background-color: #5AB571;
    }
  }

  &__text {
    color: #989898;
    font-size: 12px;
  }
}


