.jobs-grid {
  .grid-item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 0;
    transition: 0.3s ease-in-out;

    @include media-breakpoint-down(md) {
      padding: 50px 0;
      justify-content: left;
    }

    .btn {
      position: relative;
      z-index: 2;
    }

    &.has--bg-dark-grey {
      background-color: #e4e4e4;

      &:hover {
        background-color: #D6D6D6;
      }
    }

    &.has--bg-grey {
      background-color: #f9f9f9;

      &:hover {
        background-color: #F4F4F4;
      }
    }

    &.has--bg-light-grey {
      background-color: #fdfdfd;

      &:hover {
        background-color: #F1F1F1;
      }
    }

    .item-title {
      color: $dark;
      font-weight: $font-weight-bold;
      font-size: 19px;
      letter-spacing: 2.90px;
      text-transform: uppercase;
    }

    .item-bold-text {
      position: absolute;
      left: 30px;
      bottom: 0;
      text-transform: uppercase;
      font-size: 100px;
      font-weight: bolder;
      line-height: 70px;
      z-index: 0;
      @include media-breakpoint-down(lg) {
        font-size: 70px;
      }
      @include media-breakpoint-down(md) {
        left: 15px;
        font-size: 40px;
        line-height: 38px;
      }

      &.has--color-dark-grey {
        color: #ededed;
      }

      &.has--color-grey {
        color: #efefef;
      }

      &.has--color-light-grey {
        color: #f7f7f7;
      }
    }
  }
}

.teaser-text {
  font-size: 18px;
  padding-bottom: 50px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
  }
}

.job-card {
  margin: 10px;
  max-width: 420px;

  .job-card__img {
    width: 100%;
    height: 250px;
    object-fit: cover;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  .job-card__body {
    padding: 40px;

    @include media-breakpoint-down(md) {
      padding: 25px;
    }


    .job-card__description {
      align-items: center;

      .job-card__title {
        margin-right: 10px;
        margin-bottom: 0;
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 24px;
      }

      .job-card__gender {
        margin-bottom: 0;
      }
    }

    .job-card__sub-title {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: #ababab;
      letter-spacing: 0.74px;
      text-transform: uppercase;
    }

    .job-card__text {
      color: $dark;
      font-size: 16px;
      padding: 15px 0;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }
  }
}

.job-slider-section {
  .bold-text-parallax {
    @include media-breakpoint-down(md) {
      font-size: 80px;
    }

    &.has--parallax-left {
      top: 0;
    }

    &.has--parallax-right {
      bottom: -30px;
      right: 0
    }
  }

  .slider-navigation-arrow {

    &.is--left {
      left: -30px;
      top: 50%;
      transform: translateY(-50%);

      @include media-breakpoint-down(md) {
        top: 85%;
        left: 20%;
      }
      @include media-breakpoint-down(sm) {
        top: 205px;
        left: 40px;
      }
    }

    &.is--right {
      right: -30px;
      top: 50%;
      transform: translateY(-50%);

      @include media-breakpoint-down(md) {
        top: 85%;
        right: calc(50% - 60px);
      }
      @include media-breakpoint-down(sm) {
        top: 205px;
        right: 40px;
      }
    }
  }
}