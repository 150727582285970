.product-grid {
    padding: 0;

    @include media-breakpoint-up(xl) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include media-breakpoint-up(xxl) {
        padding-left: 60px;
        padding-right: 60px;

        .product-filter__item {
            display: none;
        }
    }
}

.product-col {
    border-bottom: 1px solid #ededed;
    flex: 0 0 100%;
    max-width: 100%;

    @include media-breakpoint-down(sm) {
        &:last-child {
            border-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        border-right: 1px solid #ededed;
        flex: 0 0 calc(100% / 2);
        max-width: calc(100% / 2);

        &:nth-child(2n) {
            border-right: 0;
        }
    }

    @media only screen and (min-width: 1700px) {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);

        &:nth-child(2n) {
            border-right: 1px solid #ededed;
        }

        &:nth-child(3n) {
            border-right: 0;
        }
    }

    @media only screen and (min-width: 2150px) {
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);

        &:nth-child(3n) {
            border-right: 1px solid #ededed;
        }

        &:nth-child(4n) {
            border-right: 0;
        }
    }
}

.product-badge {
    position: absolute;
    left: 0;
    top: 0;
    width: 175px;
    height: 34px;
    font-size: 10px;
    z-index: 100;

    @include media-breakpoint-down(md) {
        left: 10px;
        top: 20px;
    }

    &:before {
        font-size: 9px;
    }
}

.product-item {
    position: relative;

    &__image {
        width: 100%;
        object-fit: contain;
    }

    &__text {
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include media-breakpoint-up(xxl) {
            font-size: 18px;
        }
    }

    &__price {
        color: white;
        font-size: 17px;
        font-weight: 500;
        line-height: 20px;
        @include media-breakpoint-down(md) {
            font-size: 18px;
        }
    }

    &__price-info {
        font-size: 8px;
        margin-bottom: 0;
    }

    &__diet-types {
        .diet-types__item {
            width: 20px;
            height: 20px;
        }
    }

    &__add-to-cart {
        background-color: white;
    }
}

.product-grid__item {
    padding: 35px 35px 50px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 25px 25px 40px;
    }
}