.account-intern {
  .account-intern-wrapper {
    .account-intern__container {
      .account-intern__body {
        .checkout-item {
          .item-title {
            text-transform: uppercase;
            font-size: 14px;
            color: #a4a4a4;
            font-weight: $font-weight-bold;
            letter-spacing: 1.80px;
          }

          .input-group-time {
            .time-item {
              width: 100%;
              height: 65px;
              border-radius: 1px;
              box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);

              .item-btn {
                width: 80px;
                background-color: white;

                &:hover {
                  border: none;
                }

                &:focus {
                  border: none;
                }


                .item-icon {
                  color: $dark;
                }
              }
              .item-input {
                border-left: 1px solid #979797;
                border-right: 1px solid #979797;
                border-top: none;
                border-bottom: none;
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}