.offcanvas-cart {
  background-color: transparent !important;
  display: flex;
  height: 100vh;
  overflow: auto;
  padding-left: 25px;
  width: 60vw;
  z-index: $zindex-fixed + 20;

  @include media-breakpoint-down(sm) {
    background-color: $white !important;
    padding-left: 0;
    width: 100%;
    z-index: 2000;

    &::before {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  &__content-wrapper {
    background-color: $white;
    display: flex;
    min-height: 100%;
    width: 100%;

    @include media-breakpoint-down(xl) {
      padding-top: 60px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  &__close {
    border: 0 none;
    color: #4b4b4b;
    background-color: $white;
    font-size: 13px;
    font-stretch: normal;
    font-weight: $font-weight-bold;
    padding: 0;
    position: fixed;
    right: 18px;
    text-transform: uppercase;
    top: 18px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-bottom: 20px;
    min-height: 90px;
    padding: 0 30px;

    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }
  }

  &__header {
    display: flex;
    margin-top: 30px;
    padding: 0 30px;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
      margin-top: 10px;
    }

    .btn {
      font-size: 11px;
      letter-spacing: normal;
      padding: 8px 10px;

      &::after {
        content: '\f061';
        font-family: $font-family-icon;
        font-size: 8px;
        margin-left: 10px;
      }
    }
  }

  &__title {
    font-size: 38px;
    font-weight: bold;

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }

  &__save-item {
    margin-bottom: 20px;
    padding: 0 30px;
    width: 100%;

    a {
      align-items: center;
      color: $primary;
      display: flex;
      font-size: 16px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }

    .save-cart {
      align-items: center;
      background-color: $primary;
      border-radius: 20px;
      color: $white;
      display: flex;
      font-size: 8px;
      height: 20px;
      justify-content: center;
      margin-right: 10px;
      width: 20px;
    }
  }

  &__products {
    width: 100%;
  }

  &__product {
    border-bottom: 1px dashed #9c9995;
    padding: 15px 0;

    @include media-breakpoint-down(md) {
      padding: 10px 0;
    }

    &:last-child {
      border-bottom: 0 none;
    }

    &-image {
      height: 55px;
      width: 55px;
      margin-right: 10px;
      object-fit: contain;
    }

    &-title {
      p {
        font-weight: $font-weight-bold;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1.2;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }

      .product-delete {
        color: #b3b3b3;
        font-size: 13px;
        cursor: pointer;

        &:before {
          content: "\f00d";
          color: #b3b3b3;
          font-size: 13px;
          font-family: $font-family-icon;
          margin-right: 10px;
        }
      }
    }

    &-price {
      font-size: 15px;
      font-weight: $font-weight-bold;
      text-align: right;
    }

    .input-group-prepend {
      .form-control {
        height: 27px;
      }

      .btn {
        height: 27px;
        width: 27px;
      }
    }
  }

  &__voucher {
    margin-bottom: 40px;
    margin-top: 10px;
    padding: 0 30px;
    width: 100%;
    z-index: 100;

    @include media-breakpoint-down(md) {
      padding: 0;
      margin: 0;
    }

    &-header {
      color: #a4a4a4;
      font-size: 12px;
      font-weight: $font-weight-bold;
      letter-spacing: 1.5px;
      margin-bottom: 10px;
      text-transform: uppercase;
      transition: 0.3s color ease-in-out;
      will-change: color;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      &.is--open {
        .bk-i {
          transform: rotate(90deg);
          transition: 0.3s ease-in-out;
          will-change: transform;
        }
      }

      .bk-i {
        transform: rotate(0deg);
      }
    }

    &-input {
      .form-control {
        border: 1px solid #e1e2e5;
        border-radius: 30px;
        height: 50px;
        margin-right: 10px;
        padding: 0 40px;

        @include media-breakpoint-down(md) {
          height: 40px;
          padding: 0 25px;
          margin-right: 5px;
        }

        &::placeholder {
          color: #b4b4b4;
        }
      }

      .btn {
        border-radius: 50%;
        flex: 0 0 50px;
        height: 50px;
        padding: 0;

        @include media-breakpoint-down(md) {
          flex: 0 0 40px;
          height: 40px;
        }
      }
    }
  }

  &__bottom-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-header {
      font-size: 16px;
      font-weight: $font-weight-bold;
      margin-bottom: 15px;

      @include media-breakpoint-down(md) {
        line-height: 34px;
      }
    }

    &-body {
      .subtotal-text {
        margin: 0;
      }

      .subtotal-price {
        font-weight: $font-weight-bold;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }

      .discount-text {
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }

      .discount-price {
        font-weight: $font-weight-bold;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }

    .total-price-wrapper {
      .total-price {
        font-family: $headings-font-family;
        font-size: 70px;
        line-height: 55px;
        margin: 0;

        @include media-breakpoint-down(md) {
          font-size: 55px;
        }
      }

      .total-price__sub {
        font-family: $headings-font-family;
        font-size: 40px;
        line-height: 30px;
        margin-bottom: 0;
        margin-left: 10px;
        padding: 0;

        @include media-breakpoint-down(md) {
          font-size: 30px;
          line-height: 55px;
        }
      }

      .total-price__text {
        font-size: 12px;
        margin-right: -3px;
        padding: 0;
        text-align: right;

        @include media-breakpoint-down(md) {
          line-height: 30px;
        }
      }
    }
  }

  &__summary {
    background-color: #5F5F5F;
    color: $white;
    position: relative;
    width: 100%;

    &-col {
      padding: 30px;

      @include media-breakpoint-down(md) {
        padding: 10px;
      }

      &:first-child {
        border-right: 1px dashed #A2A2A2;

        @include media-breakpoint-down(md) {
          border: none;
        }
      }
    }


    &-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      margin-bottom: 20px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0 0;
      font-size: 16px;

      &.is--total {
        margin-top: 20px;
      }
    }

    &-total-label {
      .first {
        font-weight: 600;
        font-size: 20px;
        margin: 0;
        line-height: 1;
      }

      .second {
        font-size: 14px;
      }
    }

    .btn-checkout {
      margin-top: 25px;
    }

    &:before {
      background-position: right -22px;
      background-image: url("../../images/border-left-grey.png");
      background-size: 100% auto;
      background-repeat: repeat;
      content: '';
      height: 100%;
      left: -32px;
      position: absolute;
      top: 0;
      width: 35px;
    }

    @include media-breakpoint-down(md) {
      padding: 30px 20px;
    }

    @include media-breakpoint-down(sm) {
      &::before {
        display: none;
      }
    }
  }

  &__message {
    i {
      font-size: 120px;
      opacity: 0.2;
    }

    p {
      font-weight: 500;
      font-size: 20px;
    }
  }
}