.sale-list {
    .sale-list-header {
        .sale-list-header__col-title {
            text-transform: uppercase;
            color: #9c9c9c;
            font-size: 11px;
            font-weight: $font-weight-bold;
            letter-spacing: 0.8px;

        }
    }

    .sale-list-item {
        .sale-list-item__title {
            background-color: white;
            box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);
            transition: 0.3s;
            color: $dark;
            align-items: center;

            .item-order {
                letter-spacing: 0.7px;
                font-size: 14px;
                color: $dark;
                font-weight: $font-weight-bold;

                &-number {
                    font-weight: normal;
                    font-size: 10px;
                }
            }

            .item-period-time {
                color: #bdbdbd;
                font-weight: 500;
                font-size: 14px;
            }

            .item-date {
                color: #bdbdbd;
                font-size: 14px;
                font-weight: 500;
            }

            .item-number {
                font-size: 14px;
                font-weight: $font-weight-bold;
                letter-spacing: 0.80px;
            }

            .item-period {
                font-size: 14px;
                font-weight: 500;
            }

            .item-price {
                font-weight: 500;
                font-size: 14px;
                color: $dark;
            }

            .item-btn {
                height: 52px;
                max-width: 52px;
                background-color: #d2d2d2;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s;
                color: white;
                padding: 0;
                width: 100%;

                @include media-breakpoint-down(md) {
                    min-height: 62px;
                    max-width: 45px;
                }

                &:hover {
                    background-color: $primary;
                    color: white;
                }

                &.btn-mobile {
                    @include media-breakpoint-down(md) {
                        text-align: center;
                    }

                    &:after {
                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }

                .item-btn__text {
                    margin-bottom: 0;
                    padding-right: 5px;
                    text-transform: uppercase;
                    font-weight: $font-weight-bold;
                    font-size: 11px;
                    letter-spacing: 0.7px;
                }
            }
        }

        a.sale-list-item__title {
            &:hover {
                .item-btn {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }

    .sale-list-body {
        background-color: white;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.03);
        padding: 50px 60px;

        @include media-breakpoint-down(md) {
            padding: 50px 20px;
        }

        .order-card-result {
            padding: 30px 0 0 0;
        }

        .body-title {
            font-size: 12px;
            color: #a4a4a4;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: $font-weight-bold;
            margin-bottom: 10px;
        }

        .card-address > p {
            margin-bottom: 0;
            line-height: 1.57;
        }

    }

    .btn-wrapper {
        .btn-primary {

            @include media-breakpoint-down(lg) {
                margin-bottom: 20px;
            }
        }

        .delete-order-btn {
            color: #b6b6b6;
            font-weight: $font-weight-bold;
            font-size: 14px;

            @include media-breakpoint-down(lg) {
                padding-top: 15px;
            }
        }
    }
}

