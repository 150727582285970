.slider-navigation-arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    cursor: pointer;
    transition: 300ms ease-in-out;
    z-index: 100;
    color: $primary;
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
        background: $primary;
        color: $white;
    }

    &.is--white {
        border-color: $white;
        color: $white;

        &:hover {
            background: $white;
            color: $primary;
        }
    }

    &.arrow-xl {
        width: 200px;
        height: 200px;
        border-radius: 200px;
        @include media-breakpoint-down(xl) {
            width: 80px;
            height: 80px;
            border-radius: 80px;
        }
        @include media-breakpoint-down(md) {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
    }

    &.arrow-md {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        @include media-breakpoint-down(md) {
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }
    }

    .slider-navigation-arrow__icon {
        font-size: 30px;
        transition: 300ms ease-in-out;
        @include media-breakpoint-down(xl) {
            font-size: 15px;
        }
    }
}