.hero-slider {
    &__outer {
        width: 100%;
        height: 100vh;
        position: relative;
    }

    &__inner {
        width: 100%;
        height: 100vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            padding-bottom: 100px;
        }
    }

    &__overlay {
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.49);
        z-index: 2;
    }

    &__slides {
        color: white;
        width: 100%;
        height: 100vh;
        z-index: 5;
    }

    &__sub-title {
        font-size: 60px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        @include media-breakpoint-down(lg) {
            font-size: 50px;
        }
        @include media-breakpoint-down(md) {
            font-size: 23px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }

    &__text {
        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    &__site {
        position: absolute;
        bottom: 60px;
        left: 20px;
        z-index: 6;

        @include media-breakpoint-down(xl) {
            left: 50%;
            transform: translateX(-50%);
            bottom: 95px;
        }

        &-wrapper {
            color: white;
            display: flex;
            align-items: center;
        }

        &-current-index {
            font-weight: $font-weight-bold;
            font-size: 21px;
            letter-spacing: -0.2px;
            padding-right: 4px;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
        }

        &-amount {
            font-size: 13px;
            font-weight: 400;
            padding-left: 4px;
        }
    }

    &__scroll-down {
        position: absolute;
        bottom: 100px;
        font-size: 30px;
        color: white;
        z-index: 6;

        @include media-breakpoint-down(xl) {
            bottom: 35px;
        }
    }

    .slider-navigation-arrow {
        top: 50%;
        transform: translateY(-50%);
        z-index: 6;

        @include media-breakpoint-down(xl) {
            bottom: 70px;
            width: 95px;
            height: 95px;
            top: auto;
            transform: translateY(0);
        }

        &.is--left {
            left: -42px;

            @include media-breakpoint-down(xl) {
                left: -5px;
            }
        }

        &.is--right {
            right: -42px;

            @include media-breakpoint-down(xl) {
                right: -5px;
            }
        }
    }

    .hero-image {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        object-fit: cover;
        z-index: 1;
    }

    .progress-bar {
        position: absolute;
        bottom: -245px;
        left: 32px;
        z-index: 10;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .carousel-item {
        width: 100%;
        height: 100vh;
    }
    .carousel-caption {
        position: absolute;
        right: 0;
        left: 0;
        bottom: auto;
        top: 50%;
        text-align: left;
        padding-bottom: 0;
        padding-top: 0;
        transform: translate(0, -50%);

        @include media-breakpoint-down(xl) {
            .outline-text {
                font-size: 120px;
            }
        }

        @include media-breakpoint-down(lg) {
            .outline-text {
                font-size: 60px;
            }
        }

        @include media-breakpoint-down(sm) {
            .outline-text {
                font-size: 48px;
            }
        }
    }

    .vidbg-container {
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        video {
            position: absolute;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.25s opacity ease-in-out;
            max-width: none;
            opacity: 1;
            min-width: 100vw;
        }
    }

    .vidbg-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}