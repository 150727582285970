.inquiry {
    &-overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        bottom: 0;
        z-index: 1;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 20px;

        &__icon {
            width: 60px;
            color: #68d391;
        }
    }
}

.google-recaptcha__text {
    font-size: 12px;
}