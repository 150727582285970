.branch-detail {
  padding-top: 50px;
  padding-bottom: 100px;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 0;
  }

  &__map {
    height: 70vh;
    position: relative;
    z-index: 0;
    padding-top: 35px;
    @include media-breakpoint-down(sm) {
      height: 45vh;
      padding-top: 0;

      .leaflet-top .leaflet-control {
        margin-top: 40px;
      }
    }

    &-image{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__header {
    .branch-detail__title {
      font-size: 46px;
      font-family: $font-family-base;
      font-weight: $font-weight-bold;
      @include media-breakpoint-down(md) {
        font-size: 35px;
      }
    }

    .branch-detail__sub-title {
      color: $primary;
      font-weight: 500;

      .branch-detail__place {
        font-size: 18px;
      }
    }
  }

  &__contact {
    &-header {
      color: #a4a4a4;
      font-weight: $font-weight-bold;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    &-title {
      font-weight: $font-weight-bold;
    }
  }

  .breadcrumb {
    margin-bottom: 20px;
  }
}

.branch-image {
  height: 60vh;
  width: 100%;
  object-fit: cover;
}

