.outline-text {
    -webkit-text-stroke-width: 1px;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    text-transform: uppercase;

    &.lg-primary {
        font-size: 140px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: $primary;

        @include media-breakpoint-down(lg) {
            font-size: 120px;
        }
        @include media-breakpoint-down(md) {
            font-size: 60px;
            margin-left: 0;
        }
    }

    &.xl-primary {
        font-size: 420px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: $primary;

        @include media-breakpoint-down(md) {
            font-size: 60px;
            margin-left: 0;
        }
    }

    &.md-grey {
        font-size: 100px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: #b7b7b7;

        &.main-font-family {
            font-family: $font-family-base;
        }

        @include media-breakpoint-down(md) {
            font-size: 60px;
            margin-left: 0;
        }
    }

    &.md-light {
        font-size: 100px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: $white;

        &.main-font-family {
            font-family: $font-family-base;
        }

        @include media-breakpoint-down(md) {
            font-size: 60px;
            margin-left: 0;
        }
    }

    &.lg-light {
        font-size: 240px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: $white;

        &.main-font-family {
            font-family: $font-family-base;
        }

        @include media-breakpoint-down(md) {
            font-size: 60px;
            margin-left: 0;
        }
    }

    &.xl-primary {
        font-size: 420px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: transparentize(#866d5d, 0.5);

        @include media-breakpoint-down(md) {
            font-size: 100px;
            margin-left: 0;
        }
    }

    &.xl-dark {
        font-size: 420px;
        line-height: 1;
        margin-left: -10px;
        -webkit-text-stroke-color: #363636;

        @include media-breakpoint-down(md) {
            font-size: 100px;
            margin-left: 0;
        }
    }

    &.md-primary {
        font-size: 100px;
        -webkit-text-stroke-color: $primary;
    }

    &.has--parallax-left,
    &.has--parallax-right {
        position: absolute;
        transform: rotate(270deg);
        z-index: 6;
    }

    &.has--parallax-left {
        left: -270px;
    }

    &.has--parallax-right {
        right: -322px;
    }
}


.special-heading {
    &__title {
        font-family: "Turbinado Bold Pro", "Montserrat", sans-serif;
        font-size: 120px;
        font-weight: 400;
        margin-bottom: 0px;
        line-height: 90px;

        @include media-breakpoint-down(lg) {
            font-size: 70px;
            line-height: 60px;
        }
        @include media-breakpoint-down(md) {
            line-height: 1em;
            font-size: 48px;
        }

        &.has--size-sm {
            font-size: 106px;
            line-height: 1em;

            @include media-breakpoint-down(lg) {
                font-size: 70px;
            }
            @include media-breakpoint-down(md) {
                font-size: 48px;
            }
        }
    }

    &__sub-title {
        color: #b0b0b0;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: $font-weight-bold;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            margin: 0;
        }
        @include media-breakpoint-down(sm) {
            line-height: 1.3em;
            padding-bottom: 15px;
            padding-top: 10px;
        }
    }

    &.is--light {
        color: white;
    }
}

.bold-text-parallax {
    text-transform: uppercase;
    font-weight: bolder;

    &.is--md-size {
        font-size: 140px;

        @include media-breakpoint-down(md) {
            font-size: 80px;
        }
    }

    &.is--grey {
        color: #ebebeb;
    }
}

.signature {
    font-size: 40px;
    font-family: 'Zeyada';
}