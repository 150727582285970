.time-badge {
  font-size: 15px;
  font-weight: $font-weight-bold;
  color: $white;
  width: 200px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down(md) {
    width: 200px;
    height: 40px;
    font-size: 12px;
  }


  &.is--open {
    background-image: url("../../images/open.svg");
  }

  &.is--closed {
    background-image: url("../../images/closed.svg");
  }

  &.is--closed-shortly {
    background-image: url("../../images/soon-closed.svg");
  }
}