.edit-btn {
  height: 30px;
  width: 30px;
  border-radius: 30px;
  background-color: #ededed;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    height: 25px;
    width: 25px;
    border-radius: 25px;
  }

  .edit-btn-icon {
    font-size: 12px;
    color: $dark;

    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
  }

  &.has--position-main {
    right: 20px;
    top: 20px;
  }
  &.has--position-first {
    right: 20px;
    top:-25px;

    @include media-breakpoint-down(md) {
      right: 0px;
      top: 0px;
    }
  }

  &.has--position-second {
    right: -40px;
    top:-25px;

    @include media-breakpoint-down(md) {
      right: 0px;
      top: 0px;
    }
  }
}