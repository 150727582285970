.btn-loader {
    position: absolute;
    left: calc(50% - 10px);
    font-size: 16px;
    width: 20px;
}

.btn-custom {
    font-weight: $font-weight-bold;
    border: 1px solid;
    text-transform: uppercase;
    font-size: 11px;
    padding: 10px 24px;
    outline: none;
    letter-spacing: 1.2px;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;

    &.w-100 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.btn-success {
        color: white;
        border: none;
        background: rgb(119, 174, 114);
        background: linear-gradient(90deg, rgba(119, 174, 114, 1) 0%, rgba(128, 195, 122, 1) 100%);
        padding-left: 40px;
        padding-right: 60px;

        &::after {
            content: '\f061';
            font-family: $font-family-icon;
            height: 35px;
            width: 35px;
            border-radius: 35px;
            background-color: #6b9e65;
            position: absolute;
            right: 0;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.btn-primary {
        color: white;
        border: none;
        background: rgb(204, 158, 103);
        background: linear-gradient(90deg, rgba(204, 158, 103, 1) 0%, rgba(228, 177, 116, 1) 100%);
        padding-left: 40px;
        padding-right: 60px;

        &::after {
            content: '\f061';
            font-family: $font-family-icon;
            height: 35px;
            width: 35px;
            border-radius: 35px;
            background-color: #cc9e67;
            position: absolute;
            right: 0;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.has--icon-arrow-left {
            background: rgb(204, 158, 103);
            background: linear-gradient(90deg, rgba(228, 177, 116, 1) 0%, rgba(204, 158, 103, 1) 100%);
            padding-left: 60px;
            padding-right: 40px;

            &::after {
                content: '\f060';
                left: 0;
                right: auto;
            }
        }
    }

    &.btn-light {
        color: $primary;
        padding-left: 40px;
        padding-right: 60px;

        &::after {
            content: '\f061';
            font-family: $font-family-icon;
            height: 35px;
            width: 35px;
            border-radius: 35px;
            background-color: #f9f2eb;
            position: absolute;
            right: 0;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.btn-outline-primary {
    &:hover {
        color: $white;
    }
}
