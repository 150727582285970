.customer-account {
  .account-information {
    .account-information__container {
      max-width: 1200px;
      width: 100%;

      @include media-breakpoint-down(lg) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .form-title {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2.6px;
      color: #a4a4a4;
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(xl) {
        font-size: 12px;
        line-height: 1.2;
        display: block;
      }
    }

    .account-information__btn {
      text-align: center;

      .form-alert {
        color: #4b4b4b;
      }
    }
  }
}