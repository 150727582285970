.account-intern {
  .account-intern__body {
    .sale-list {
      .sale-list-item {
        .sale-list-item__title {
          .item-request {
            font-weight: $font-weight-bold;
            font-size: 14px;
            color: $dark;
          }

          .item-name {
            color: $dark;
            font-size: 14px;
          }

          .item-company {
            color: $dark;
            font-size: 14px;
          }
        }

        .sale-list-body {
          .order-card-item {
            &:last-child {
              border-bottom: 1px dashed rgba(0, 0, 0, 0.20);
            }
          }

          .btn {
            height: 70px;
            border-radius: 3px;
            width: 100%;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: $font-weight-bold;

            &:hover {
              border: none;
            }

            &.is--accept {
              background-color: #5ab571;
            }

            &.is--reject {
              background-color: #db5b5b;
            }

            .icon-check {
              font-size: 18px;
            }

            .icon-reject {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}

