.content-teaser {
  .progress-bar-wrapper {
    top: 0;
    left: 0;
    @include media-breakpoint-down(sm) {
      left: 40px;
    }

    .progress-bar {
      right: 25px;
    }
  }

  &.has--padding {
    padding-top: 90px;
    padding-bottom: 60px;
    @include media-breakpoint-down(md) {
      padding-top: 60px;
      padding-bottom: 25px;
    }
  }
}