.toolbox-accordion {
  .accordion {
    .card {
      box-shadow: none;
      margin-bottom: 15px;

      .card-header {
        padding: 10px 15px;
        background: $primary;
        border: 1px solid $primary;

        .accordion-toggle {
          color: white;
          font-weight: bold;

          &:hover, &:focus {
            color: $dark;
          }
        }
      }

      .collapse {
        border: 1px solid #ececec;
        border-top: none;

        .card-body {
          .card-body__content {
            padding: 25px;

            @include media-breakpoint-down(md) {
              padding: 10px;
            }
          }
        }
      }
    }
  }
}