.search-overlay {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  z-index: -2 ;
  overflow-x: scroll;

  @include media-breakpoint-down(xl) {
    top: 60px;
  }

  @include media-breakpoint-down(md) {
    background-color: $white;
  }

  .search-overlay__input-container {
    background-color: white;
    padding-top: 70px;

    @include media-breakpoint-down(lg) {
      padding-top: 60px;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
    }

    .close-wrapper {
      padding: 20px;
      top: 70px;
      right: 130px;
      color: $dark;
      cursor: pointer;
      line-height: 1em;
      opacity: 0.8;
      transition: 0.3s ease-in-out;
      @include media-breakpoint-down(lg) {
        top: 80px;
        right: 0;
      }
      @include media-breakpoint-down(md) {
        top: 60px;
      }

      &:hover {
        opacity: 0.3;
      }

      .close-text {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1.40px;
        font-weight: $font-weight-bold;
        margin-bottom: 0;
        padding-top: 6px;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      .close-icon {
        font-size: 50px;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
        }
      }
    }

    .search-field {
      padding-bottom: 60px;
      margin: 0 auto;
      @include media-breakpoint-down(lg) {
        padding-bottom: 50px;
      }
      @include media-breakpoint-down(md) {
        padding-bottom: 30px;
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 0;
      }

      .search-icon {
        font-size: 25px;
      }

      .search-loading-icon {
        position: absolute;
        right: 0;
        font-size: 23px;
        color: $primary;
      }

      .search-input {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #d0d0d0;
        padding: 25px 60px;
        @include media-breakpoint-down(md) {
          padding: 20px 40px;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &::placeholder {
          font-size: 17px;
          color: #b8b8b8;
        }
      }
    }
  }

  .search-overlay__container {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .search-overlay__results-container {
    width: 100%;
    background-color: white;

    .result-text {
      font-size: 14px;
      font-weight: $font-weight-bold;
      letter-spacing: 0.50px;
      text-transform: uppercase;
      color: #b8b8b8;
    }

    .product-item {
      display: block;
      padding: 15px 0;
      border-bottom: 1px dashed #141414;
      max-width: none;
      height: 90px;
      top: 0;

      &:last-child {
        border-bottom: none;
        margin-bottom: 70px;
      }

      .item-image {
        height: 60px;
        width: 60px;
        object-fit: contain;
      }

      .item-description {
        color: $dark;
        font-size: 17px;
        font-weight: $font-weight-bold;
        transition: 0.3s ease-in-out;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .search-overlay__backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 70px;
    bottom: 0;
    z-index: -1;
  }
}