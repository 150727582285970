.production-process-slider {
    counter-reset: process-step;

    .keen-slider {
        overflow: hidden;
        padding: 100px 0 35px;
        position: relative;
        @include media-breakpoint-down(lg) {
            padding-top: 50px;
            padding-bottom: 0;
        }
    }

    &__slide {
        overflow: visible !important;

        .carousel-card__number {
            &:before {
                counter-increment: process-step;
                content: '0' counter(process-step);
            }
        }

        &.is--active {
            z-index: 2;

            .carousel-card {
                opacity: 1;
                transform: scale(1.2);
                transition: transform 300ms ease;

                @include media-breakpoint-down(sm) {
                    transform: none;
                }
            }
        }
    }
}