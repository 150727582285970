@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.product-item {
  margin: 0 auto;
  overflow: hidden;

  &__stock-info {
    position: absolute;
    z-index: 5;
    right: 0;
    left: 0;
    bottom: 6px;
    font-size: 12px;
    text-align: center;
    height: 42px;
    padding-left: 40px;
    padding-right: 40px;

    strong {
      white-space: nowrap;
    }

    .pulsating-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 10px;
      height: 10px;

      &:before {
        content: '';
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        box-sizing: border-box;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 45px;
        background-color: $green;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $green;
        border-radius: 15px;
        box-shadow: 0 0 8px rgba(0, 0, 0, .3);
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
      }
    }

  }

  &__link {
    display: block;
  }

  &__badge {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 30px;
  }


  .custom-badge {
    position: absolute;
    right: 10px;
    top: 10px;

    .title {
      font-size: 9px;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 10;
      width: 100%;
    }

    .product-item__badge {
      position: relative;
      top: 0;
      left: auto;
      z-index: 5;
    }
  }

  &__image {
    height: auto;
    width: 100%;
  }

  &__information-outer {
    bottom: -54px;
    position: absolute;
    display: flex;
    justify-content: center;
    right: 0;
    left: 0;
  }

  &__information-wrapper {
    background: #363636;
    content: "";
    height: 80px;
    margin: 0 12px 0 17px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    position: relative;
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
    will-change: transform;

    &:before {
      background: url('../../images/product-item-left.svg');
      background-size: 100% 100%;
      content: "";
      height: 100%;
      left: -17px;
      position: absolute;
      top: 0;
      width: 19px;
    }

    &:after {
      background: url('../../images/product-item-right.svg');
      background-size: 100% 100%;
      content: "";
      height: 100%;
      position: absolute;
      right: -12px;
      top: 0;
      width: 13px;
    }

    .product-item__text {
      color: white;
      flex-wrap: wrap;
      font-family: $font-product-item;
      font-size: 16px;
      line-height: 1.4;
      word-break: break-word;
    }

    .product-price {
      &__default {
        color: white;
        font-size: 16px;
        font-weight: $font-weight-bold;

        &.is--discounted {
          color: $red;
        }
      }

      &__retail-price {
        font-size: 12px;
        margin-left: 5px;
        opacity: 0.4;
        text-decoration: line-through;
      }

      &__information {
        color: white;
        font-size: 8px;
        line-height: 12px;
      }

      &__seperator {
        display: none;
      }

      &__tax {
        display: none;
      }
    }

    .product-item__diet-types {
      flex-wrap: wrap;
      margin-left: -10px;
      margin-top: 5px;
      min-width: 80px;

      .diet-types {
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }

  &__price-tax-info {
    color: #ccc;
    font-size: 10px;
    text-align: right;
    margin-bottom: 0;
  }

  &__information {
    max-width: 100%;
    width: 100%;

    .btn-primary {
      justify-content: center;
      padding-left: 15px;
      padding-right: 45px;
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      transform: translateY(0px);
    }
  }

  form {
    transform: translateY(0);
    transition: transform 300ms ease-in-out;
    will-change: transform;
  }

  &:hover {
    .product-item__information-wrapper {
      transform: translateY(-50px);
    }

    form {
      transform: translateY(-50px);
      transition-delay: 100ms;
    }
  }
}