.wishlist-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);
  max-width: 780px;
  width: 100%;
  z-index: 1072;

  .wishlist-modal__inner {
    padding: 40px;

    .close-wrapper {
      padding: 20px;
      top: 0;
      right: 0;
      cursor: pointer;

      .close-icon {
        font-size: 25px;
      }
    }

    .wishlist-title {
      color: $dark;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }

    .wishlist-product {
      border-bottom: 1px dashed #bcbcbc;
      padding-top: 20px;
      padding-bottom: 20px;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      .product-image {
        height: 50px;
        margin-right: 20px;

        @include media-breakpoint-down(md) {
          height: 30px;
        }
      }

      .product-title {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 14px;
      }

      .product-price {
        font-size: 16px;
        font-weight: 500;
        font-family: $font-family-base;
        padding-top: 0;
        padding-right: 20px;
      }

      .product-delete {
        color: #b3b3b3;
        font-size: 13px;
        cursor: pointer;

        &:before {
          content: "\f00d";
          color: #b3b3b3;
          font-size: 13px;
          font-family: $font-family-icon;
          margin-right: 10px;
        }
      }
    }
  }
}