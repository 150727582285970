.branches {
  min-height: 100vh;

  .branches-header {
    padding: 50px 60px;

    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }

    .branches-text {
      @include media-breakpoint-down(md) {
        padding: 20px 0;
      }
      @include media-breakpoint-down(sm) {
        padding: 10px 0;
      }
    }
  }

  .branch-grid {
    border-top: 1px solid #e9e9e9;

    .branch-grid__col {
      border-bottom: 1px solid #e9e9e9;
      border-right: 1px solid #e9e9e9;

      &:nth-child(2) {
        border-right: none;
      }

      .branch-grid__item {
        padding: 90px 30px;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $dark;
        height: 100%;

        @include media-breakpoint-down(md) {
          padding: 30px 20px;
          align-items: flex-start;
        }

        &.is--active {
          background-color: $primary;
          color: white;
        }

        &:hover {
          background-color: $primary;
          color: white;
          transition: 0.3s;
        }

        .item-title {
          font-size: 18px;
          font-weight: $font-weight-bold;
          letter-spacing: 3.8px;
          text-transform: uppercase;

          @include media-breakpoint-down(md) {
            margin-bottom: 0;
            font-size: 16px;
          }
        }

        .item-sub-title {
          margin-bottom: 0;
          font-size: 14px;
        }
      }
    }
  }
}