.card {
  border: none;
  box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;

  .card-body {
    padding: 0;

    .card-title{
      border-left: 5px solid $primary;
      padding-left: 40px;
      margin-bottom: 0;
    }
    .card-text{
      padding: 40px;

      @include media-breakpoint-down(md) {
        padding: 30px;
      }
    }
  }
}