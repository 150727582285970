.intern-login {
  .intern-login__image-wrapper {
    height: 100vh;
    width: 100%;

    .login-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .outline-text {
      transform: rotate(-90deg);
      top: 600px;
      left: -550px;
      line-height: 200px;
      font-size: 190px;
    }
  }

  .login-form {
    .login-title {
      font-size: 32px;
      color: $dark;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .select-card {
      width: 400px;
      border-radius: 30px;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
      padding: 20px 40px;

      &::placeholder {
        font-weight: $font-weight-bold;
      }
    }
    .input-card {
      width: 400px;
      border-radius: 30px;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
      padding: 20px 40px;
      margin-left: auto;
      margin-right: auto;

      &::placeholder {
        color: #cacaca;
      }
    }

    .company-select {
      padding: 20px 20px;
      background-color: white;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
      border-radius: 0.8px;
      margin-bottom: 10px;
      color: $dark;
      font-size: 14px;
      width: 400px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $dark;
        color: white;
      }

      &.is--active {
        background-color: $dark;
        color: white;
      }
    }
  }

  .password-forgot {
    color: $dark;
  }
}