.rating-header {
    .rating-quote {
        font-size: 100px;
        color: #866d5d;
        left: -80px;
        position: absolute;

        @include media-breakpoint-down(lg) {
            font-size: 52px;
            position: inherit;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            top: -49px;
        }
    }

    .rating-header__title {
        font-size: 24px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            padding-top: 20px;
        }
    }

    .rating-stars {
        color: #e7cb5e;
        font-size: 24px;

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }

        .rating-stars__average {
            color: $dark;
            margin-left: 10px;
            margin-bottom: 0;
            margin-top: 10px;

            @include media-breakpoint-down(md) {
                font-size: 20px;
            }
        }
    }
}

.post-rating {
    padding-right: 25px;
    padding-left: 25px;
    max-width: 387px;

    @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
    }

    .post-rating__header {
        .post-rating__header-wrapper {
            .rating-time {
                color: #c2c2c2;
                font-weight: 500;
                font-size: 14px;
            }

            .rating-name {
                font-weight: 500;
                font-size: 14px;
            }
        }

        .rating {
            display: flex;
            align-items: center;

            .rating-star {
                font-size: 18px;
                color: #e7cb5e;
                margin-right: 10px;
            }

            .rating-average {
                font-size: 30px;
                margin: 0;
                line-height: 1em;
                padding-top: 6px;
                margin-left: 10px;
            }
        }
    }

    .post-rating__title {
        font-weight: $font-weight-bold;
        font-size: 18px;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .post-rating__text {
        line-height: 1.4em;
    }
}

.stars-rating {
    margin-bottom: 20px;

    .stars-rating__title {
        color: #a4a4a4;
        text-transform: uppercase;
        letter-spacing: 1.8px;
        font-weight: 500;
    }

    .stars-rating__stars {
        font-size: 30px;
        color: #a4a4a4;

        .stars-rating-icon {
            font-size: 40px;
        }
    }
}

.rating-form {
    &__input {
        border-radius: 30px;
        border: 0.4px solid #bcbcbc;
        padding: 0 26px;
        line-height: 48px;

        &.is--textarea {
            border-radius: 30px;
            height: 150px;
            line-height: 1.65;
            padding-bottom: 20px;
            padding-top: 20px;
        }

        &::placeholder {
            color: #999999;
        }
    }
}

.rating-body {
    .nav-tabs-wrapper{
        .nav-tabs {
            @include media-breakpoint-down(lg){
                flex-wrap: initial;
            }

            .nav-item {
                width: 50%;

                .nav-link {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include media-breakpoint-down(md) {
                        font-size: 11px;
                    }

                    .bk-i {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .slider-navigation-arrow {
        top: calc(50% + 30px);
        @include media-breakpoint-down(sm) {
            top: calc(100% - 60px);
        }

        &.is--left {
            left: -40px;
            @include media-breakpoint-down(lg) {
                left: -15px;
            }
            @include media-breakpoint-down(sm) {
                left: calc(50% - 60px);
            }
        }

        &.is--right {
            right: -40px;
            @include media-breakpoint-down(lg) {
                right: -15px;
            }
            @include media-breakpoint-down(sm) {
                right: calc(50% - 60px);
            }
        }
    }
}