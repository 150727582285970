.order-revise {
  padding: 200px 0;
  background: #fafafa;
  @include media-breakpoint-down(md) {
    padding: 120px 0;
  }

  .special-heading__title {
    font-size: 70px;
    margin-bottom: 70px;
    line-height: 1em;
  }

  .form-control  {
    background-color: #fff;
    padding: 20px 13px;
    margin-bottom: 10px;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    border: none;
    font-size: 16px;
    min-height: 64px;
  }
}