.newsletter-section {
  color: #767676;

  &.has--padding {
    padding-top: 110px;
    padding-bottom: 100px;
    @include media-breakpoint-down(sm) {
      padding-top: 110px;
      padding-bottom: 60px;
    }
  }

  .bold-text-parallax {
    bottom: -40px;
    left: 200px;

    @include media-breakpoint-down(lg) {
      bottom: -16px;
      left: 16px;
    }

    @include media-breakpoint-down(sm) {
      top: -12px;
      left: -5px;
    }
  }
}