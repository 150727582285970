.company-card {
  width: 100%;
  height: 450px;
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    height: 340px;
  }

  .company-card__overlay {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.16) 32%, #000000);
    z-index: 2;
  }

  .company-card__image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  .company-card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform ease 500ms;
  }

  &:hover {
    .company-card__image {
      transform: scale(1.2);
    }
  }

  .time-badge {
    width: 200px;
    height: 40px;
    font-size: 11px;
    top: 20px;
    right: -20px;
    z-index: 3;
  }
  .company-card__item {
    bottom: 20px;
    left: 20px;
    z-index: 4;

    .item-name {
      text-shadow: 2px 3px 9px rgba(0, 0, 0, 0.09);
      color: white;
      font-weight: $font-weight-bold;
      font-size: 25px;
      margin-bottom: 5px;
      line-height: 1.2;
    }
    .item-place {
      text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.09);
      color: white;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}