.image-content-card {
  height: 100vh;
  @include media-breakpoint-down(xl) {
    height: auto;
    padding: 100px 0;
  }
  @include media-breakpoint-down(sm) {
    .container {
      max-width: calc(100% - 40px);
    }
  }

  &__text {
    padding: 30px 0;
    color: white;

    @include media-breakpoint-down(md) {
      padding: 10px 0;
    }
  }

  &__content-wrapper {
    background-color: $primary;
    transform: translate(-40px, 50px);
    @include media-breakpoint-down(md) {
      transform: translate(10px, -40px);
    }

    &::before {
      content: "";
      width: 100%;
      height: 20px;
      background-image: url("../../images/primary-border-top.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: -10px;
      left: 0;

      @include media-breakpoint-down(md) {
        top: -6px;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 20px;
      background-image: url("../../images/primary-border-bottom.png");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -20px;
      left: 0;
    }

    .image-content-card__content-inner {
      padding: 70px;

      @include media-breakpoint-down(lg) {
        padding: 70px 65px;
      }

      @include media-breakpoint-down(md) {
        padding: 70px 15px;
      }


      &::before {
        content: "";
        width: 20px;
        height: 100%;
        background-image: url("../../images/primary-border-left.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: -12px;

        @include media-breakpoint-down(lg) {
          left: -8px;
        }
      }

      &::after {
        content: "";
        width: 20px;
        height: 100%;
        background-image: url("../../images/primary-border-right.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 0;
        right: -20px;
      }
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    transform: translate(40px, -50px);
    @include media-breakpoint-down(md) {
      transform: translate(-35px, 0);
    }
  }

  .outline-text {
    top: 20%;
    @include media-breakpoint-down(xl) {
      top: 10%;
      font-size: 190px;
    }
    @include media-breakpoint-down(md) {
      top: 4%;
      font-size: 125px;
    }
  }

  &.has--bg-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    content: "";
    background-image: url("../../images/bg-bottom.png");
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-down(md) {
      background-size: 1000px;
      background-position: bottom;
    }
  }
}