.application-heading  {
  .heading-title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1.20;
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      font-size: 28px;
    }
  }
  .heading-sub-title {
    margin-bottom: 0;
    font-style: italic;
    font-size: 22px;
    line-height: 1.50;
  }
}

.application-form__step {
  .step-header {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 50px;

    .header-item {
      display: flex;
      align-items: center;
      height: 60px;
      background-color: white;


      .item-number {
        font-weight: bold;
        font-size: 27px;
        color: #e2e2e2;
        margin-bottom: 0;
        padding-left: 40px;

        @include media-breakpoint-down(md) {
          padding-left: 5px;
          font-size: 20px;
        }
      }

      .item-icon-check {
        display: none;
        color: #00873f;

        &.is--active {
          display: flex;
        }

        @include media-breakpoint-down(md) {
          font-size: 10px;
        }
      }
      .item-name {
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        letter-spacing: 0.80px;
        color: $dark;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }

    .item-icon-angle {
      color: white;
      font-size: 10px;

      &.is--close {
        transform: rotate(-180deg);
      }
    }

  }
  .step-body {
      margin-bottom: 100px;
      margin-right: -10px;

    .form-group {
      margin-right: 10px;


      .input-card{
        height: 60px;
      }
      .select-card {
        height: 60px;
        padding: 0 20px;
      }
    }
    .input-group {
      padding-right: 10px;

      .input-group-prepend {
        height: 60px;
      }
      .input-card {
        height: 60px;
      }
    }

    .application-form__text {
      font-size: 14px;
      font-weight: $font-weight-bold;
      letter-spacing: 1.20px;
      color: #b7b7b7;
      text-transform: uppercase;
    }

    .added-btn {
      .added-btn-icon__wrapper {
        height: 20px;
        width: 20px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        color: white;
        margin-right: 10px;

        .added-btn-icon {
          color: white;
          font-size: 14px;
        }
      }
      .added-btn__text {
        font-weight: $font-weight-bold;
        font-size: 12px;
      }
    }


    .selected-file {
      .file-text {
        font-size: 14px;
        color: $dark;
        font-style: italic;

      }
      .file-icon-close {
        color: #bdbdbd;
        font-size: 16px;
      }
    }

    .btn-search {
      background-color: white;
      border: none;
      color: #bababa;

      .search-icon-upload {
        margin-right: 10px;
        font-size: 14px;
      }

      &::after {
        background-color: #d2d2d2;
        color: white;
      }
    }
  }
}