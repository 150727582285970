.incoming-order {
  min-width: 730px;

  .incoming-order-header {
    .order-header__title {
      color: #a4a4a4;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 2.10px;
    }
    .order-header__sub-title {
      color: $dark;
      font-size: 20px;
    }
  }
  .incoming-order-body {
    .order-body__title {
      color: #a4a4a4;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 2.10px;
    }

    .card-address {
      padding-bottom: 50px;
    }

    .card-address > p {
      margin-bottom: 0;
    }

    .order-body__wrapper {
      max-height: 300px;
      overflow-y: auto;

      .order-card-item:last-child {
        border: none;
      }
    }
  }

  .incoming-order-footer {
    .order-footer__title {
      color: #a4a4a4;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 2.10px;
      margin-bottom: 20px;
    }

    .btn {
      border: none;
      height: 70px;
      border-radius: 3px;
      width: 100%;

      &.is--order-open {
        background-color: #db5b5b;
      }
      &.is--ready-for-delivery {
        background-color:#ecb34c;
      }
      &.is--order-close {
        background-color: #5ab571;
      }
    }
  }
}