.related-products-slider {
    padding: 200px 0;

    @include media-breakpoint-down(lg) {
        padding: 150px 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 100px 0;
    }

    .slider-navigation-arrow {
        top: 50%;
        transform: translateY(-50px);

        @include media-breakpoint-down(xl) {
            top: calc(50% + 70px);
            width: 50px;
            height: 50px;
            border-radius: 50px;
        }

        &.is--left {
            left: -35px;
            @include media-breakpoint-down(xl) {
                left: -10px;
            }
        }

        &.is--right {
            right: -35px;
            @include media-breakpoint-down(xl) {
                right: -10px;
            }
        }
    }

    .special-heading {
        .special-heading__title {
            @include media-breakpoint-down(md) {
                font-size: 60px;
            }
        }
    }
}