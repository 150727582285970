.login-wrapper {
  width: 50%;

  @include media-breakpoint-down(md) {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 0 25px;

    form {
      width: 100%;
    }
  }

  .login-title {
    font-size: 24.5px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  form {
    .form-group {
      margin-bottom: 25px;

      label:not(.custom-control-label) {
        color: #a4a4a4;
        font-size: 14px;
        letter-spacing: 1.80px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
      }

      .form-control {
        border: 1px solid #bcbcbc;
        border-radius: 30px;
        min-height: 40px;
        width: 100%;
        padding: 15px 25px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .login-item {
    .item-pw-reset {
      color: $dark;
      font-size: 12px;
    }

    .item-register-reset {
      color: $dark;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .triangle-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 16px solid white;
    top: -56px;
    right: -8px;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}