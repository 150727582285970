.recurring-order-modal {
    transform: translate(-50%, -50%) !important;
}
.recurring-order-modal h5 {
    color: #4b4b4b;
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: bold;
}
.recurring-order-modal .weekdays .row {
    margin-left: -5px;
    margin-right: -5px;

    .col {
        padding-left: 5px;
        padding-right: 5px;
    }
}
.recurring-order-modal .modal-content {
    background: #fafafa ;
    border-radius: 0;
    box-shadow: none;
}
.recurring-order-modal .modal-content .modal-header {
    padding: 40px !important;
}
.recurring-order-modal .modal-content .modal-header .close {
    font-size: 25px;
    margin: 0;
    padding: 0;
}
.recurring-order-modal .modal-content .modal-body {
    padding: 0 40px !important;
}
.recurring-order-modal .modal-content .modal-footer {
    padding: 20px 40px 40px !important;
}
.recurring-order-modal .form-group .input-card {
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
}