.nutrient-table__title {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-size: 14px;
}

.nutrient-table {
  .nutrient-table__item {
    border-top: 1px dashed rgba(0, 0, 0, 0.20);

    &:first-child {
      border-top: 0;
    }

    .item-nutrient {
      padding-left: 0;
      border-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
    }

    .item-gram {
      padding-right: 0;
      border-top: 0;
      text-align: end;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
    }
  }
}