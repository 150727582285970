.password-reset {
  .password-reset__image-wrapper {
    height: 100vh;
    width: 100%;

    .password-reset-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .outline-text {
      transform: rotate(-90deg);
      top: 600px;
      left: -550px;
      line-height: 200px;
      font-size: 190px;
    }
  }

  .password-reset-wrapper {
    @include media-breakpoint-down(md) {
      height: 100vh;
      padding: 0 20px;
    }
  }

  .password-reset-form {
    .password-reset-title {
      font-size: 32px;
      color: $dark;
      font-weight: bold;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        font-size: 28px;
      }
    }

    .password-reset-text {
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .input-card {
      width: 400px;
      border-radius: 30px;
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.03);
      padding: 20px 40px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &::placeholder {
        color: #cacaca;
      }
    }
  }
}