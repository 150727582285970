.account-intern {
  &-wrapper {
    height: 100%;
    min-height: 100vh;
    background-color: #fafafa;
  }

  &__container {
    padding: 100px 20px;
    max-width: 1500px;
    margin: 0 auto;
    padding-bottom: 0;

    @include media-breakpoint-down(xl) {
      padding: 30px 20px;
    }
  }

  &__title {
    font-weight: $font-weight-bold;
    color: $dark;
    font-size: 30px;
    margin-bottom: 40px;

    @include media-breakpoint-down(xl) {
      margin-bottom: 20px;
    }
  }
}