.checkbox-card {
  background-color: white;
  cursor: pointer;
  padding: 25px 60px;
  margin-bottom: 10px;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);

  @include media-breakpoint-down(md) {
    padding: 20px 20px;
  }

  .custom-checkbox {
    .checkbox-card__image {
      height: 18px;
      width: auto;
      object-fit: contain;
    }
  }

  &__wrapper {
    display: block;
  }

  &__label {
    display: block;
  }

  &__input {
    display: none;
  }

  &.active {
    background-color: $primary;
    color: white;
  }

  &.disable {
    background-color: #dcdcdc;
    color: white;

    .custom-checkbox {
      .custom-control-label:before {
        border: 1px solid white;
      }

      .custom-control-label:after {

      }
    }
  }

  .form-control {
    border: none;
    border-radius: none;
    height: 20px;
    margin-right: 10px;
    padding-left: 0;

    &.has--box-shadow {
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.04);
    }

    &::placeholder {
      color: #cacaca;
    }
  }
}