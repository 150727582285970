
.local-pick-up {
    .primary {
        background: var(--primary);
    }

    .accent {
        background: var(--primary);
    }
}
