.account-intern__body {
    .quantity-input {
        max-width: none;
    }

    .inventory-item {
        background-color: white;
        border-radius: 3px;
        padding: 10px 30px;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }

        .item-image {
            height: 60px;
        }

        .item-name {
            font-size: 16px;
            font-weight: $font-weight-bold;
            color: $dark;
        }

        .quantity-input {
            .input-group-prepend {
                .form-control {
                    border: 1px solid #c1c1c1;
                    background-color: white;
                    margin-left: 20px;
                    width: 100px;
                    border-radius: 30px;
                }
            }
        }

        .item-badge {
            cursor: pointer;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            background-color: #5ab571;
            color: white;
            display: none;
            align-items: center;
            justify-content: center;
            margin-left: 36px;

            &.is--active {
                display: flex;
            }
        }
    }
}
