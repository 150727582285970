.thank-you {
  padding: 200px 0;
  background: #fafafa;
  @include media-breakpoint-down(md) {
    padding: 120px 0;
  }

  &__text {
    margin-top: 5px;
    margin-bottom: 25px;

    a {
      color: $dark;
    }
  }

  &__btn-wrapper {
    margin-bottom: 70px;
  }

  .special-heading__title {
    font-size: 70px;
    line-height: 1em;
  }
}