.slide-enter-active {
    transition-duration: 500ms;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 500ms;
    transition-timing-function: ease-out;
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    max-height: 0;
    overflow: hidden;
}