*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f8f8f8;
}

*::-webkit-scrollbar {
    width: 6px;
    background-color: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
}