.page-checkout {
  .main-footer {
    display: none;
  }

  .checkbox-card__wrapper {
    margin: 30px 0;
    position: relative;

    @include media-breakpoint-down(md) {
      margin: 0;
    }

    .checkbox-card__header {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: $font-weight-bold;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }

    .checkbox-card__text {
      @include media-breakpoint-down(md) {
        font-size: 12px;
        text-align: center;
      }
    }

    .checkbox-card__title {
      color: #a4a4a4;
      font-size: 14px;
      letter-spacing: 1.80px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }

    .checkbox-card__body {



      @include media-breakpoint-down(md) {
        font-size: 14px;
      }

      .checkbox-card__payment-image {
        height: 30px;
        width: auto;

        @include media-breakpoint-down(md) {
          height: 25px;
        }
      }
    }
  }
}

.process {
  .checkbox-card {
    &.is--aligned-center {
      @include media-breakpoint-up(md) {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }


    &__label {
      position: relative;
    }

    &.is--hover {
      &:hover {
        background-color: $primary;
        color: white;
        transition: transform .3s ease-in-out;

        .custom-checkbox {
          .custom-control-label:before {
            border: 1px solid white;
          }
        }
      }
    }

    &.is--disabled {
      background-color: #dcdcdc;
      color: white;
      cursor: default;
    }
  }
}

.process_step__tooltip {
  &-headline {
    font-size: 12px;
    line-height: 1.3;
  }

  ul {
    padding-left: 0;

    li {
      line-height: 1.3;
      font-size: 12px;
      list-style: none;
      padding-left: 10px;
      position: relative;

      &:before {
        background: $white;
        border-radius: 50%;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 2px;
      }
    }
  }
}

.checkout-content {
  padding: 20px;
  margin-top: 100px;
  background: #f9f9f9;

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
}