.nav-tabs-wrapper {
  .slider-navigation-arrow {
    top: 50%;
    transform: translateY(-50px);

    &.is--left {
      left: -35px;

      @include media-breakpoint-down(md) {
        left: 50px;
        top: initial;
        bottom: -150px;
      }
    }

    &.is--right {
      right: -35px;

      @include media-breakpoint-down(md) {
        right: 50px;
        top: initial;
        bottom: -150px;
      }
    }
  }

  .nav-tabs {
    .nav-item {
      .nav-link {
        color: #b8b8b8;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        letter-spacing: 2.6px;
        padding: 20px 50px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: none;
        height: 100%;

        @include media-breakpoint-down(md) {
          padding: 15px 15px;
          font-size: 12px;
        }

        &.active, &:hover, &:focus {
          border-bottom: 2px solid #866d5d;
          color: #866d5d;
        }
      }
    }
  }
}

