.account-sidebar {
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.05);
  flex: 0 0 400px;
  height: auto;
  z-index: 1000;
  max-width: 400px;
  background-color: white;
  min-height: 100vh;

  @include media-breakpoint-down(xl) {
    max-width: 340px;
    flex: 0 0 340px;
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 0;
    top: 0;
    min-height: auto;
    width: 100%;
    padding-top: 100px;
    transform: translateX(-100%);
    bottom: 0;
    transition: 0.3s ease-in-out;

    &.is--open {
      transform: translateX(0);
    }
  }

  &-backdrop {
    &.is--open {
      transform: translateX(0);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: transparentize($black, 0.5);
      z-index: 10;
    }
  }

  .sidebar-header {
    padding-top: 150px;
    padding-bottom: 80px;
    text-align: center;

    @include media-breakpoint-down(xl) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .sidebar-header__type {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      color: #CCCCCC;
      font-weight: $font-weight-bold;
      margin-bottom: 5px;
    }

    .sidebar-header__name {
      color: #4b4b4b;
      font-size: 30px;
      text-align: center;
      font-weight: bold;

      @include media-breakpoint-down(xl) {
        font-size: 20px;
        padding: 0;
        margin-bottom: 0;
      }
    }

    .sidebar-header__branch {
      @include media-breakpoint-down(xl) {
        font-size: 14px;
      }
    }
  }

  .sidebar-nav {
    width: 100%;

    .navbar-nav {
      position: relative;
      transition: 0.3s;

      .nav-item {
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        position: relative;

        &:last-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }


        @include media-breakpoint-down(lg) {
          &:first-child {
            border-top: none;
          }
        }

        &:before {
          content: "";
          width: 5px;
          height: 100%;
          position: absolute;
          background-color: $primary;
          left: 0;
          top: 0;
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }


        &.is--active {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            height: 100%;
            background-color: $primary;
            opacity: 1;
          }
        }

        .nav-link {
          color: $dark;
          text-transform: uppercase;
          font-weight: $font-weight-bold;
          display: flex;
          padding: 30px 100px;
          position: relative;
          align-items: center;

          .item-badge {
            width: 17px;
            height: 17px;
            border-radius: 17px;
            font-size: 12px;
            color: white;
            background-color: #db5b5b;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;

          }


          &:after {
            content: "\f061";
            font-size: 12px;
            color: $primary;
            font-family: $font-family-icon;
            position: absolute;
            right: 40px;
          }

          .nav-icon {
            position: absolute;
            color: #bdbdbd;
            font-size: 22px;
            left: 50px;
          }
        }
      }
    }
  }


  .sidebar-footer {
    padding-top: 80px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      padding-top: 20px;
      padding-bottom: 0;
    }

    .sidebar-footer__btn {
      color: #a7a7a7;
      border: 1px solid #a7a7a7;
      font-size: 12px;
      padding: 10px 50px;
      line-height: 1em;

      &:hover {
        background-color: #a7a7a7;
        color: white;
        transition: 0.3s;
      }

      &:focus {
        background-color: #a7a7a7;
        color: white;
        border: none;
        box-shadow: none;
      }
    }

    .sidebar-footer__logout {
      color: #a7a7a7;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      letter-spacing: 0.5px;
      font-size: 12px;
      margin-left: 20px;
    }
  }
}