.sort-by-btn {
  width: auto;
  height: 37px;
  font-size: 10px;

  .sort-by-label {
    .sort-by-text{
      background-color: transparent;
      padding-left: 20px;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border: 1px solid #bcbcbc;
      border-right: none;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .sort-by-select {
    border: 1px solid #bcbcbc;
    padding: 7px 10px;
    border-radius: 30px;
    height: initial;
    border-left: none;
    font-weight: $font-weight-bold;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border-radius: 30px;
    padding-right: 30px;
    line-height: 1em;
    line-height: 23px;

    @include media-breakpoint-down(sm) {
      border: 1px solid #bcbcbc !important;
      padding-left: 30px !important;
      border-radius: 30px !important;
    }
  }

  .sort-by-select:focus {
    box-shadow: none;
  }
}