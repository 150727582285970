.message {
  .card-item {
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #909090;
      color: white;

      .item-header {
        .item-title {
          color: white;
        }
        .item-date {
          color: white;
        }
      }
      .item-body {
        .item-text {
          color: white;
        }
      }
    }

    &.is--unread {
      border-left: 2px solid $primary;
    }

    &.is--active {
      background-color: #909090;
      color: white;

      .item-header {
        .item-title {
          color: white;
        }
        .item-date {
          color: white;
        }
      }
      .item-body {
        .item-text {
          color: white;
        }
      }
    }

    .item-header {
      .item-title {
        font-size: 14px;
        color: $dark;
        font-weight: $font-weight-bold;
      }
      .item-date {
        font-size: 12px;
        font-weight: 500;
        color: #a5a5a5;
      }
    }
    .item-body {
      .item-text {
        margin-bottom: 0;
        color: #a8a8a8;
        font-size: 12px;
      }
    }
  }

  .message-card {
    background-color: white;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.03);

    .message-card__header {
      background-color: #909090;
      padding: 20px 50px;

      @include  media-breakpoint-down(md) {
        padding: 20px 30px;
      }

      .header-item__sender {
        color: white;
        font-size: 14px;
        font-weight: $font-weight-bold;

        .sender-item {
          color: white;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .header-item__date {
        color: white;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .message-card__body {
      padding: 50px;

      @include  media-breakpoint-down(md) {
        padding: 30px 30px;
      }

      .card-body__title {
        font-weight: 500;
      }
    }
  }
}