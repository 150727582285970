.shop {
  .btn {
    font-size: 10px;
    height: 35px;
    white-space: nowrap;
  }
}







