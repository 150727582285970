.datepicker {
  &__arrow {
    background: $white;
    border: 0 none;
    width: 37px;

    &.is--left {
      border-right: 1px solid #f2f2f2;
    }

    &.is--right {
      border-left: 1px solid #f2f2f2;
    }

    i {
      color: $dark;
      font-size: 12px;
    }

    &:hover {
      background: $primary;

      i {
        color: $white;
      }
    }
  }

  .b-calendar-grid {
    &.form-control {
      border: none;
    }

    &:focus {
      box-shadow: none !important;
    }
  }


  .b-form-datepicker {
    border: 0 none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    cursor: pointer;
    line-height: 62px;
    padding: 0 30px;

    > button {
      padding: 0;
    }

    > .form-control {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding-left: 15px;
    }

  }

  &-select-dates {
    &__date {
      background: $white;
      border: 0 none;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
      padding: 25px;

      span {
        display: block;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.37;
      }

      &:hover {
        background: $primary;

        span {
          color: $white;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 20px;

        span {
          font-size: 11px;
        }
      }
    }
  }
}

