.preloader {
  position: fixed;
  background: transparentize($white, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;

  &::after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 1;
    animation: slide 2s infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}