.custom-page-section {
  &.has--padding {
    padding-top: 220px;
    padding-bottom: 150px;
    @include media-breakpoint-down(xl) {
      padding-top: 180px;
      padding-bottom: 90px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 90px;
      padding-bottom: 30px;
    }
  }
}