
.payment-provider-select {
    .provider-item {
        padding: 20px 40px;
        border: 1px solid #ccc;
        margin-bottom: 10px;

        .custom-control-label {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .provider-logo {
                height: 20px;
                width: auto;
                margin-right: 15px;
            }

            .provider-name {
                display: block;
                font-weight: bold;
                text-align: right;
            }
        }
    }
}
