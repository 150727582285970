.b-form-timepicker {
    border: 0 none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    cursor: pointer;
    line-height: 62px;
    padding: 0 30px !important;

    > button {
        padding: 0;
    }

    .form-control {
        border: 0 none;
    }

    label {
        font-size: 15px;
        font-weight: 500;
        line-height: 62px;
        margin: 0 0 0 10px !important;
    }
}

.vue__time-picker {
    width: 100% !important;
}
.vue__time-picker input.display-time {
    border: 0 none !important;
    width: 100% !important;
}
.vue__time-picker .controls {
    display: none !important;
}
.vue__time-picker .dropdown {
    top: 65px !important;
    width: 100% !important;
}
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: #cc9e67 !important;
}