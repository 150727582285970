.image-content-teaser {

  &.has--padding {
    padding-top: 120px;
    padding-bottom: 100px;

    @include media-breakpoint-down(md) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .image-content-teaser__text {
    font-size: 18px;
    color: $dark;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .signature {
    transform: rotate(-2deg);
  }

  .image-content-teaser__image {
    height: auto;
    width: 100%;
  }
}