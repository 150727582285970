.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    color: #989898;
    font-size: 12px;
    padding: 25px 0;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
        padding: 20px 0;
        margin: 0;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #989898;
        content: "\f054";
        font-family: $font-family-icon;
    }

    .breadcrumb-item > a {
        color: #989898;
    }

    .breadcrumb-item {
        &.active {
            font-weight: $font-weight-bold;
        }
    }
}
