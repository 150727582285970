.form-control {
  @include media-breakpoint-down(md) {
    appearance: none;
  }
}

.custom-select {
  padding: 10px 15px;
}

.newsletter-wrapper {
  .newsletter__label {
    font-family: $headings-font-family;
    font-size: 55px;
    margin-right: 20px;
    margin-bottom: 0;
    padding-top: 5px;

    &.is--bg-dark {
      color: white;
    }
  }

  .input--hidden {
    display: none !important;
  }

  .sib-form-message-panel {
    margin: 0 0 1.25rem 0;
    width: 100%;
    padding: 0.4375rem;
    border: 1px solid;
    display: none;

    &__text {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0.5rem;

      .sib-notification__icon {
        height: 1.5em;
        width: 1.5em;
        flex-shrink: 0;
        margin-right: calc(1rem - 1px)
      }
    }

    &--active {
      display: inline-block;
    }
  }

  .sib-loader {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px
  }

  .form-control {
    border: none;
    border-radius: 30px;
    height: 50px;
    margin-right: 10px;
    padding-left: 30px;

    &.has--box-shadow {
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.04);
    }

    &::placeholder {
      color: $dark;
    }

    &.is--bg-dark {
      background-color: #444444;
      color: white;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.newsletter__button {
  width: 50px;
  padding: 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;

  &:hover {
    color: $white;
  }
}

.search-btn {
  .form-control {
    width: 100%;
    border-radius: 30px;
    height: 50px;
    margin-right: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: transparent;

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

  }

  .form-btn {
    width: 50px;
    padding: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
      box-shadow: none;
    }

    &:before {
      color: $primary;
      content: "\f061";
      font-family: $font-family-icon;
      font-size: 14px;
    }

    &:hover {

      &:before {
        color: $white;
      }
    }
  }
}


// Checkbox

.custom-checkbox {
  font-size: 14px;
  padding-left: 29px;

  .custom-control-label {
    display: flex;
    cursor: pointer;
  }

  .custom-control-input:checked ~ .custom-control-label:before {
    border: 1px solid $white;
  }

  .custom-control-label:before {
    border: 1px solid #ccc;
    background: transparent;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    left: -28px;
  }

  .custom-control-label:after {
    color: $dark;
    background: no-repeat;
    position: absolute;
    top: 6px;
    left: -23px;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
  }
}

// Input-Card
.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 20px;

  &.has--input {
    .label-card {
      display: block;
    }

    .input-card {
      padding-top: 28px;
      padding-bottom: 12px;
    }
  }

  .label-card {
    display: none;
    position: absolute;
    font-size: 12px;
    color: #cacaca;
    left: 28px;
    top: 7px;
  }

  .input-card {
    background-color: white;
    padding: 20px 13px;
    margin-bottom: 10px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    border: none;
    font-size: 16px;
    min-height: 64px;
    @include media-breakpoint-down(md) {
      padding: 20px 20px;
      margin-bottom: 5px;
    }

    &::placeholder {
      color: #cacaca;
      font-size: 14px;
    }
  }

  .select-card {
    background-color: white;
    padding: 20px 13px;
    margin-bottom: 10px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    border: none;
    height: 64px;

    @include media-breakpoint-down(md) {
      padding: 20px 20px;
    }

    &.has--bg {
      background-color: #e4e4e4;
    }

    &::placeholder {
      color: #cacaca;
    }
  }
}

//Input-Group

.input-group {
  height: auto;

  .input-group-prepend {
    .btn {
      padding: 0 25px;
      background-color: #e4e4e4;
      border: none;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
      margin-bottom: 0px;

      @include media-breakpoint-down(md) {
        padding: 0px 15px;
      }
    }
  }

  .input-card {
    background-color: white;
    padding: 20px 30px;
    border-radius: 2px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
    border: none;
    font-size: 16px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      padding: 20px 20px;
    }

    &::placeholder {
      color: #cacaca;
    }
  }
}

