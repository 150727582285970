.pagination {
  display: flex;
  align-items: center;

  .page-item {
    .page-link {
      background-color: transparent;
      color: $dark;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }

    &.active {
      font-weight: $font-weight-bolder;
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    .pagination-nav {
      border: 1px solid $dark;
      height: 40px;
      width: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $dark;
        transition: 0.3s;

        &:after {
          color: white;
        }
      }

      &:after {
        font-family: $font-family-icon;
        font-size: 10px;
        color: $dark;
      }
    }
  }
}