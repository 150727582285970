.main-footer {
    height: auto;
    background-color: $footer-bg;
    padding-top: 100px;
    color: white;
    position: relative;
    z-index: 3;

    @include media-breakpoint-down(lg) {
        padding-top: 50px;
    }

    .row--border-bottom {
        border-bottom: 1px solid #7a7a7a;
        display: flex;
        align-items: center;
    }

    .main-footer__logo {
        height: 30px;
        width: auto;
        opacity: 0.3;
        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
            opacity: 1;
        }
    }


    .main-footer__heading {
        color: #747474;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
        font-size: 14px;
        text-transform: uppercase;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }

    .footer-information {
        color: #cacaca;
    }

    .footer-information > p {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .footer-information > p > a {
        color: #cacaca;
    }

    .main-footer__nav {
        list-style: none;
        column-count: 2;

        &:hover {
            color: white;
        }
    }

    .main-footer__nav > li {
        break-inside: avoid-column;
        padding-bottom: 10px;
    }

    .main-footer__nav > li > a {
        color: #cacaca;
        font-size: 14px;


        &.active {
            color: white;
        }
    }

    .main-footer__payment {
        .main-footer__payment-image {
            height: 24px;
            filter: grayscale(1);
            opacity: 0.9;
            width: 62px;
            object-fit: contain;
        }
    }

    .sub-footer {
        background-color: #323232;

        .sub-footer__copyright {
            font-size: 12px;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }

        .sub-footer__links {
            list-style: none;
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
                padding-inline-start: 0;
            }
        }

        .sub-footer__links > li {
            padding-right: 10px;
            padding-left: 10px;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
            line-height: 15px;

        }

        .sub-footer__links > li:last-child {
            padding-right: 0;
            border: none;
        }

        .sub-footer__links > li:first-child {
            padding-left: 0;
        }

        .sub-footer__links > li > a {
            color: rgba(255, 255, 255, 0.5);
            font-size: 12px;
        }
    }
}

