
.account-intern__container {

  .chart-container {
    min-height: 400px;
  }

  .account-intern__body {
    .account-intern-card {
      background-color: white;
      padding: 50px;
      height: 100%;


      .intern-card__header {
        .intern-card__title {
          text-transform: uppercase;
          color: #acacac;
          font-weight: $font-weight-bold;
          letter-spacing: 1.10px;
          font-size: 14px;
        }
      }

      .intern-card__body {
        padding: 50px 0 0 0;

        .order-today {
          font-size: 85px;
          color: $dark;
        }

        .order-inquiry {
          border-bottom: 1px dashed #141414;
          padding: 20px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
          }

          .inquiry {
            margin-bottom: 0;
            color: $dark;
            font-weight: $font-weight-bold;
            font-size: 14px;
          }

          .place {
            margin-bottom: 0;
            color: $dark;
            font-size: 14px;
          }

          .angle-right {
            margin-bottom: 0;
            color: $dark;
            font-size: 14px;
          }
        }


        .order-inventory {
          border-bottom: 1px dashed #141414;
          padding: 20px 0;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }

          .inventory-image {
            height: 50px;
            object-fit: cover;
          }

          .inventory-text {
            color: $dark;
            font-size: 14px;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
          }

          .inventory-piece {
            color: #db5b5b;
            font-size: 14px;
            font-weight: $font-weight-bold;
            letter-spacing: -0.25px;
            margin-bottom: 0;
          }
        }

      }

      .intern-card__footer {
        padding-top: 50px;

        .order-yesterday {
          font-weight: 500;
          color: #acacac;
          font-size: 14px;
        }
      }
    }
  }
}
