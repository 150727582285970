.bakery {
  .page-header {
    .page-header__title {
      bottom: 37px;

      @include  media-breakpoint-down(md) {
        bottom: -20px;
      }
    }
  }
  .custom-page-section {
    .bold-text-parallax {
      font-size: 380px;
      top: 40px;

      @include media-breakpoint-down(md) {
        top: 10px;
        font-size: 80px;
      }
    }
    .outline-text {
      font-size: 200px;
      top: 400px;
      left: 600px;
      -webkit-text-stroke-color:#e7e7e7;

      @include media-breakpoint-down(md) {
        top: 90px;
        font-size: 50px;
        left: 30px;
      }
    }
    .slider-navigation-arrow {
      top: 60%;


      &.is--left {
        left: -35px;

        @include media-breakpoint-down(md) {
          left: 80px;
          top: initial;
          bottom: 100px;
        }
      }
      &.is--right {
        right: -35px;

        @include media-breakpoint-down(md) {
          right: 80px;
          top: initial;
          bottom: 100px;
        }
      }
    }
  }


}


.bakery-text {
  font-size: 18px;
}

.signature-wrapper {
  .signature-image {
    width: 100%;
    height: auto;
  }

  .signature {
    text-align: right;
    line-height: 1;
    margin-right: 150px;
    transform: rotate(-2deg);

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }
  .signature-sub-content {
    text-align: right;
    margin-right: 150px;
    margin-top: -10px;

    @include media-breakpoint-down(md) {
      margin-right: 0;
    }
  }
}

.nav-tabs-wrapper {
  .employee-card {
    height: 100%;
    width: 100%;
    padding-right: 15px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      padding-bottom: 15px;
    }

    &__overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 100%);
    }

    .employee-card__item {
      left: 0;
      bottom: 0;
      right: 0;
      color: $white;
      transition: transform 0.3s ease-in-out;
      will-change: transform;
      transform: translateY(45px);
      padding: 30px 20px;
      z-index: 5;

      .item-name {
        transition: transform 0.3s ease-in-out;
        will-change: transform;

        .item-first-name {
          margin-bottom: 0;
          color: white;
          font-size: 16px;
          letter-spacing: 0.08px;
        }

        .item-surname {
          color: white;
          text-transform: uppercase;
          letter-spacing: 0.33px;
          font-weight: $font-weight-bold;
          font-size: 20px;
          margin-bottom: 0;
        }
      }

      .item-position {
        color: $primary;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        will-change: opacity;
      }
    }

    &:hover {
      .employee-card__item {
        transform: translateY(20px);

        .item-position {
          opacity: 1;
        }

        .item-name {
          padding-bottom: 0;
        }
      }
    }
  }
}
