.job-detail {

  .job-detail__content {
    @include media-breakpoint-up(md) {
      height: 100vh;
      overflow-y: scroll;
    }
  }

  .job-detail__image-wrapper {
    width: 100%;
    position: relative;

    .job-detail__image-overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35);
    }

    .job-detail__image {
      width: 100%;
      height: 100vh;
      object-fit: cover;

      @include media-breakpoint-down(md) {
        height: 300px;
      }
    }

    .outline-text {
      left: -465px;
      top: 700px;
      transform: rotate(-90deg);
      line-height: 100px;
      font-size: 180px;

      @include media-breakpoint-down(md) {
        transform: inherit;
        font-size: 60px;
        left: 0;
        bottom: 0;
      }
    }
  }

  .job-detail__wrapper {
    padding: 0 100px;

    @include media-breakpoint-down(md) {
      padding: 10px;
    }

    .breadcrumb {
      padding-top: 100px;

      @include media-breakpoint-down(md) {
        padding-top: 0;
      }

    }

    .job-detail__information {
      .information-heading {
        font-weight: $font-weight-bold;
        font-size: 18px;
        color: $dark;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }

      .information-title__wrapper {
        padding: 0 0 50px 0;

        @include media-breakpoint-down(md) {
          padding: 0 0 10px 0;
        }

        .information-title {
          color: #cc9e67;
          font-size: 55px;
          font-weight: bold;
          margin: 0;

          @include media-breakpoint-down(md) {
            font-size: 36px;
          }
        }

        .information-gender {
          color: $dark;
          font-size: 18px;

          @include media-breakpoint-down(md) {
            line-height: 10px;
          }
        }
      }

      .information-text {
        padding-bottom: 40px;
      }

      .information-time-title {
        margin-bottom: 10px;
        font-weight: $font-weight-bold;
        color: #b0b0b0;
        letter-spacing: 0.62px;
        text-transform: uppercase;
      }
    }

    .job-detail__expectation {
      .expectation-list {

        @include media-breakpoint-down(md) {
          padding: 0;
        }


        .list-item {
          margin-bottom: 20px;
          list-style: none;
          margin-left: 20px;
          padding-left: 20px;

          &::marker {
            content: "\f061";
            font-family: $font-family-icon;
            color: $primary;
            font-size: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .job-detail__benefits {
      .benefits-list {

        @include media-breakpoint-down(md) {
          padding: 0;
        }

      }

      .list-item {
        margin-bottom: 20px;
        list-style: none;
        margin-left: 20px;
        padding-left: 20px;

        &::marker {
          content: "\f061";
          font-family: $font-family-icon;
          color: $primary;
          font-size: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .job-detail__paid-monthly {
      .paid-monthly__text {
        color: white;
        font-size: 20px;

        @include media-breakpoint-down(md) {
          font-size: 17px;
        }

      }
    }

    .bold-text-parallax {
      bottom: 0;
      right: 0;
      font-size: 70px;

      @include media-breakpoint-down(md) {
        font-size: 40px;
      }
    }
  }

  .outline-text {

    &.md-grey {
      font-size: 70px;
      top: 80px;
      right: 0;

      @include media-breakpoint-down(md) {
        font-size: 36px;
        top: 120px;
      }

    }

    &.md-primary {
      font-size: 70px;
      top: 50px;
      left: 0;

      @include media-breakpoint-down(md) {
        font-size: 36px;
        right: 0;
        top: 130px;
      }
    }
  }

  .application-opportunity {
    margin: 100px 0;
    padding: 30px 0;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
      margin: 0;
    }


    &.has--border-right {
      border-right: 1px dashed #141414;

      @include media-breakpoint-down(md) {
        border-bottom: 1px dashed #141414;
        border-right: none;
      }
    }

    .opportunity-title {
      font-weight: $font-weight-bold;
      font-size: 16px;
      letter-spacing: 0.80px;
      color: $dark;
      text-transform: uppercase;
    }

    .opportunity-text {
      color: $dark;
      font-size: 15px;
    }
  }
}