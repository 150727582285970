.intern-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .filter-header {
    margin-right: 30px;

    .filter-header__title {
      font-size: 14px;
      color: #a2a2a2;
      text-transform: uppercase;
      letter-spacing: 1.10px;
      font-weight: $font-weight-bold;
    }
  }

  .filter-body {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .filter-body__checkbox {
      background-color: white;
      border-radius: 30px;
      margin-bottom: 10px;
      padding: 10px 20px;
      color: $dark;
      cursor: pointer;
      min-width: 200px;
      margin-right: 10px;

      .custom-control {
        min-height: 0;
      }

      &:hover {
        background-color: $dark;
        color: white;

        .checkbox-count {
          color: white;
        }
      }

      &.is--active {
        background-color: $dark;
        color: white;
        font-weight: $font-weight-bold;

        .checkbox-count {
          color: white;
        }
      }

      @include media-breakpoint-down(xl) {
        padding: 10px;

        .custom-checkbox {
          font-size: 12px;
        }
      }

      .checkbox-count {
        font-size: 12px;
        color: #b6b6b6;
        letter-spacing: -0.18px;
        font-style: italic;
      }
    }
  }
}