.process {
  background-color: #fafafa;
  padding: 140px 40px;
  @include media-breakpoint-down(sm) {
    padding: 90px 0;
  }

  &-sidebar {
    flex: 0 0 500px;
  }

  &-header {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;

    &__link {
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.3;
    }
  }

  &-step__number {
    position: absolute;
    font-family: $headings-font-family;
    font-size: 50px;
    padding: 10px 0;
    background-color: #fafafa;
    color: #a2a2a2;
    top: -26px;
    left: -20px;
    width: 40px;
    text-align: center;

    &:before {
      counter-increment: process-step;
      content: '0' counter(process-step);
    }

    @include media-breakpoint-down(md) {
      font-size: 40px;
    }
  }

  &-step {
    border-left: 1px solid #a2a2a2;
    padding: 0 0 60px 50px;
    position: relative;
    margin-left: 20px;

    &__wrapper {
      max-width: 832px;
    }

    .row {
      margin-left: -5px;
      margin-right: -5px;

      div[class*='col'] {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    &__btn {
      text-transform: uppercase;
      font-weight: $font-weight-bold;

      &::after {
        content: '\f061';
        font-family: $font-family-icon;
        font-size: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__title {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }

    &__sub-title {
      color: #666;
      font-size: 14px;
      letter-spacing: 1.80px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 10px;
    }

    &__map {
      height: 304px;
      margin: 0 0 45px;
      max-width: 625px;
      width: 100%;
    }

    &-payment {
      &__select {
        align-items: center;
        display: flex;
        height: 75px;
        justify-content: center;
        padding: 0 25px;

        &.active {
          background: $white !important;
          border: 1px solid $primary !important;
        }

        &:hover {
          background: $white !important;
          border: 1px solid $primary !important;
        }
      }

      &__image {
        max-height: 36px;
        max-width: 75px;
      }

      &__input {
        display: none;
      }
    }

    .form-group {
      .input-card {
        margin-bottom: 0;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0 20px 20px 40px;
      margin-left: 20px;
    }
  }

  &-container {
    counter-reset: process-step;

    @include media-breakpoint-up(md) {
      .process-step:last-child:not(:first-child) {
        border: none;
      }
    }

    .process-step-summary {
      .checkbox-card__body {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .checkbox-card {
    padding-left: 30px;
    padding-right: 30px;
  }
}