.image-slider {
    &__item {
        height: 70vh;

        @include media-breakpoint-down(lg) {
            height: 50vh;
        }
    }

    .carousel-caption {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        @include media-breakpoint-down(lg) {
            max-width: 80%;
            margin: 0 auto;
        }
        @include media-breakpoint-down(sm) {
            max-width: 70%;
        }

        .outline-text {
            font-size: 120px;
            line-height: 1.1em;
            @include media-breakpoint-down(lg) {
                font-size: 70px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 30px;
            }
        }

        .bold-text {
            line-height: 1.1em;
            font-size: 60px;
            font-weight: bold;
            @include media-breakpoint-down(lg) {
                font-size: 40px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }
    }

    &-section {
        .slider-navigation-arrow {
            top: calc(50% - 90px);
            cursor: pointer;
            @include media-breakpoint-down(xl) {
                top: calc(50% - 40px);
            }
            @include media-breakpoint-down(sm) {
                top: calc(100% - 80px);
            }

            &.is--left {
                left: -35px;
                @include media-breakpoint-down(xl) {
                    left: -15px;
                }
                @include media-breakpoint-down(sm) {
                    left: calc(50% - 60px);
                }
            }

            &.is--right {
                right: -35px;
                @include media-breakpoint-down(xl) {
                    right: -15px;
                }
                @include media-breakpoint-down(sm) {
                    right: calc(50% - 60px);
                }
            }
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: brightness(0.6);
    }
}
