.has--divider-bottom:after,
.has--divider-top:before,
.has--divider-top-grey:before {
  content: "";
  width: 100%;
  height: 80px;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 5;
  @include media-breakpoint-down(sm) {
    height: 30px;
  }
}

.has--divider-bottom {
  &:after {
    background-image: url("../../images/divider-white.png");
    bottom: -80px;

    @include media-breakpoint-down(sm) {
      bottom: -30px;
    }
  }
}

.has--divider-top {
  &:before {
    background-image: url("../../images/divider-horizontal-oben.png");
    top: -80px;
    @include media-breakpoint-down(sm) {
      top: -30px;
    }
  }
}

.has--divider-top-grey {
  &:before {
    background-image: url("../../images/kante_oben.png");
    top: -80px;
    @include media-breakpoint-down(sm) {
      top: -30px;
    }
  }
}

