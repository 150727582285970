.page-product-detail {
    .page-top {
        @include media-breakpoint-down(md) {
            padding-top: 0;
        }
    }

    .custom-page-section {
        .btn {
            &.add-to-cart {
                @include media-breakpoint-down(md) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .product-detail__map {
            height: 450px;

            @include media-breakpoint-down(sm) {
                height: 288px;
            }

            &.is--blured {
                filter: blur(8px);
            }

            &-overlay {
                position: absolute;
                background: transparentize($white, 0.7);
                display: flex;
                flex-direction: column;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 10;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                text-align: center;

                .time {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

        .product-detail__nutrients-chart {
            position: relative;
            z-index: 2;
        }

        .product-detail__nutrients-info {
            position: absolute;
            left: 0;
            top: 50%;
            font-size: 58px;
            font-weight: $font-weight-normal;
            line-height: 1em;
            color: #4b4b4b;
            right: 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            z-index: 1;
            transform: translate(0, -50%);

            @include media-breakpoint-down(sm) {
                font-size: 40px;
            }

            .label {
                line-height: 1em;
                font-size: 20px;
            }
        }

        .product-images-column {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        .product-images {
            .product-images__thumbnails {
                height: 452px;
                flex: 0 0 88px;
                margin-right: 13px;

                @include media-breakpoint-down(md) {
                    height: 40px;
                    width: auto;
                }

                .image-pagination {
                    width: 100%;
                    opacity: 0.2;

                    @include media-breakpoint-down(md) {
                        width: auto;
                        height: 80px;
                        margin-right: 5px;
                        margin-top: 5px;
                    }

                    &.is--active {
                        opacity: 1;
                    }
                }
            }

            .product-images__slider {
                height: 100%;
                width: 100%;

                .product-images__slider-image {
                    object-fit: contain;
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 70px;
                }

                @include media-breakpoint-up(md) {
                    .product-images__slides-wrapper {
                        height: 452px;

                        .keen-slider {
                            height: 452px;
                        }

                        .product-images__slider-item {
                            .product-images__slider-image {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }

                .image-lightbox-btn {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: white;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(-180deg);

                    &:before {
                        content: "\f012";
                        font-size: 18px;
                        font-family: $font-family-icon;
                    }
                }

                .slider-navigation-wrapper {
                    right: 20px;
                    bottom: 20px;
                }
            }
        }


        .origin-title {
            color: #757575;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: $font-weight-bold;
            align-items: center;

            @include media-breakpoint-down(md) {
                font-size: 12px;
                align-items: center;
                margin-left: 2px;
            }
        }

        .product-information__title {
            color: $dark;
            font-family: $font-family-base;
            font-size: 45px;
            font-weight: bold;
            line-height: 40px;
            margin-top: 20px;

            @include media-breakpoint-down(md) {
                font-size: 35px;
                margin-top: 10px;
            }
        }

        .product-information {
            margin-top: 20px;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                font-weight: 500;
            }


            .sort-price {
                font-size: 12px;
                color: #757575;
            }

            .rating-count-label {
                font-size: 12px;
                margin-left: 10px;
                padding-left: 10px;
                color: #757575;
            }

            .sort-price + .rating-count-label {
                border-left: 1px solid #757575;
            }

            .rating-stars {
                color: #e7cb5e;
                font-size: 12px;
            }
        }

        .product-price {
            > div {
                display: block !important;
                text-align: right;
            }

            &__default {
                font-family: $headings-font-family;
                font-size: 68px;
                line-height: 0.9;
                padding-top: 45px;

                &.is--discounted {
                    color: $red;
                }

                @include media-breakpoint-down(md) {
                    padding-top: 0;
                    font-size: 45px;
                    line-height: 1;
                }
            }

            &__retail-price {
                font-family: $headings-font-family;
                font-size: 40px;
                line-height: 1;
                opacity: 0.2;
                text-decoration: line-through;

                @include media-breakpoint-down(md) {
                    font-size: 35px;
                }
            }

            &__information {
                font-size: 11px;
                color: $dark;
            }
        }

        .product-short-description {
            font-size: 16px;
            line-height: 26px;
            color: $dark;
        }

        .product-item__add-to-cart {
            .notice-wrapper {
                color: #979797;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                font-size: 12px;
                background: transparent;
                border: none;

                &:focus {
                    outline: none;
                }

                &:hover {
                    color: #6A6A6A;
                }

                @include media-breakpoint-down(md) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15px;
                }

                .notice-heart {
                    color: #979797;

                    @include media-breakpoint-down(md) {
                        margin-right: 5px;
                    }
                }
            }
        }

        .information-header {
            padding: 30px 0;
            font-family: $font-family-base;
            border-bottom: 1px solid #e2e2e2;
            color: $dark;
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 2.2px;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                font-size: 14px;
            }
        }

        .card {
            .information-header {
                padding: 30px;
            }
        }

        .diet-types-wrapper {
            .diet-types-text {
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    margin-right: 15px !important;
                }
            }

            .diet-types {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: red;

                @include media-breakpoint-down(sm) {
                    width: 25px;
                    height: 25px;
                    margin-right: 5px;
                    line-height: 25px;
                }
            }
        }

        .product-nutrient {
            text-align: center;

            .product-nutrient__wrapper {
                display: flex;
                justify-content: center;
                align-items: center;


                .product-nutrient__percent {
                    font-weight: $font-weight-bold;
                    font-size: 20px;

                    &.is--primary {
                        color: $primary;
                    }

                    &.is--grey {
                        color: #c5c5c5;
                    }

                    &.is--brown {
                        color: #866d5d;
                    }

                }

                .product-nutrient__gram {
                    font-weight: $font-weight-bold;
                    padding-left: 5px;
                    font-size: 20px;
                }
            }

            .product-nutrient__text {
                font-size: 14px;
                padding-bottom: 15px;
            }
        }

        .card {
            .card-body {
                .card-wrapper {
                    .card-item {
                        display: flex;
                        align-items: center;
                        padding: 35px;
                        border-right: 1px solid #dee2e6;
                        border-bottom: 1px solid #dee2e6;

                        &:nth-child(2n) {
                            border-right: 0;
                        }

                        .allergens-icon {
                            font-size: 30px;
                            margin-right: 15px;
                        }

                        .allergens {
                            font-size: 16px;
                            font-weight: $font-weight-bold;
                            line-height: 18px;

                            .allergens-contain {
                                font-weight: normal;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }

        .allergens-disclaimer {
            font-size: 12px;
        }

        .bold-text-parallax {
            font-size: 240px;
            color: #f1f1f1;
            position: absolute;

            @include media-breakpoint-down(md) {
                font-size: 180px;
            }

            &.has--parallax-left {
                left: 0;
                top: -20px;
            }

            &.has--parallax-right {
                right: 0;
                bottom: -60px;
            }
        }
    }

    .rating-header {
        .nav-tabs {
            .nav-item {
                @include media-breakpoint-down(md) {
                    flex: 0 0 50%;
                }

                .nav-link {
                    @include media-breakpoint-down(md) {
                        display: flex;
                        align-items: center;
                    }

                    span {

                        @include media-breakpoint-down(md) {
                            word-break: break-word;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        .slider-navigation-arrow {

            @include media-breakpoint-down(md) {
                width: 50px;
                height: 50px;
                border-radius: 50px;
            }

            &.is--left {
                border: 1px solid #866d5d;

                @include media-breakpoint-down(md) {
                    left: 35%;
                }
            }

            &.is--right {
                border: 1px solid #866d5d;

                @include media-breakpoint-down(md) {
                    right: 35%;
                }
            }

            .slider-navigation-arrow__icon {
                color: #866d5d;
            }
        }
    }
}
