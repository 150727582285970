
.animated-circle {
  height: 15px;
  width: 15px;
  background: var(--primary);
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 5;
  font-size: 12px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.72, 0.22, 0.85, 0.4);

  &.is--transforming {
    visibility: visible;
    opacity: 1;
  }
}
