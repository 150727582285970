.overview-card {
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  height: auto;
  background-color: white;

  .overview-card__link {
    width: 100%;
    height: 100%;

    .overview-card__image {
      width: 100%;
      object-fit: cover;
    }

    .overview-card__header {
      padding:50px 0 0 50px;

      .overview-card__title {
        color: $dark;
        font-weight: $font-weight-bold;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 3.80px;
      }
    }

    .overview-card__body {
      padding: 50px;
    }
  }
}