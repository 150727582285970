.multiselect {
  background-color: $white;
  padding: 0;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
  border: none;
  height: 64px;
  cursor: pointer;

  &__select {
    top: 13px;
    right: 11px;
    z-index: 2;
  }

  &__tags {
    height: 64px;
    border: 0;
    padding: 0;
  }

  &__input {
    padding: 15px 30px;
    margin-bottom: 0;
    height: 64px;

    &::placeholder {
      color: $gray-400;
    }
  }

  &__single {
    padding-left: 30px;
    height: 64px;
    margin: 0;
    line-height: 61px;
    display: flex;
    align-items: center;
  }

  &__option {
    padding: 15px 30px;

    &--selected {
      &.multiselect__option--highlight {
        background: $gray-100;
        color: $dark;
      }
    }

    &::after {
      display: none;
    }
  }

  .multiselect__content-wrapper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--above {
    .multiselect__content-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .option__desc {
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .option__title {
    padding-left: 10px;
  }
}