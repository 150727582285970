.account-intern {
  .account-intern-wrapper {
    .account-intern__container {
      .account-intern__body {
        .product-wrapper {
          background-color: white;
          padding: 30px;
          position: relative;

          .product-overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.64);
            z-index: 10000;

            .product-overlay__inner {
              height: 100%;
              width: 100%;
              position: relative;

              .overlay-check {
                height: 40px;
                width: 40px;
                border-radius: 40px;
                background-color: $primary;
                color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .product-grid__item {
            .product-item {
              overflow: hidden;

              .product-item__information {
                transition: transform .3s ease-in-out;
                will-change: transform;
                transform: translateY(0px);

                @include media-breakpoint-down(md) {
                  transform: translateY(0px);
                }
              }

              &:hover {
                .product-item__information {
                  transform: none;
                }
              }
            }
          }
        }
      }
    }
  }
}