@font-face {
    font-family: 'Chalkduster';
    src: url('./Chalkduster.woff2') format('woff2'),
        url('./Chalkduster.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Turbinado Bold Pro';
    src: url('./TurbinadoBoldPro.woff2') format('woff2'),
        url('./TurbinadoBoldPro.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Zeyada';
    src: url('Zeyada.eot');
    src: url('Zeyada.eot?#iefix') format('embedded-opentype'),
    url('Zeyada.woff2') format('woff2'),
    url('Zeyada.woff') format('woff'),
    url('Zeyada.ttf') format('truetype'),
    url('Zeyada.svg#Zeyada') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
