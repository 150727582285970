.branch-opening-times {
  .opening-time__item {
    background-color: #fbfbfb;
    border-bottom: 10px solid white;

    .item-day {
      padding: 20px 0 0 40px;
      border-top: none;
      color: $dark;
      font-weight: $font-weight-bold;
      letter-spacing: 0.94px;
      text-transform: uppercase;

      @include media-breakpoint-down(md) {
        padding: 15px;
        font-size: 12px;
      }
    }

    .item-morning {
      padding: 20px;
      border-top: none;
      border-right: 1px solid white;
      color: #c5c5c5;
      font-weight: 500;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: 15px;
        font-size: 12px;
      }
    }

    .item-afternoon {
      padding: 20px;
      border-top: none;
      color: #c5c5c5;
      font-weight: 500;
      text-align: center;

      @include media-breakpoint-down(md) {
        padding: 15px;
        font-size: 12px;
      }
    }

    &.is--active {

        .item-day {
          color: white;
          background-color:$dark;
        }

        .item-morning {
          color: white;
          background-color: #929292;
        }

        .item-afternoon {
          color: white;
          background-color: #929292;
        }

    }
  }
}