.ingredients-slider {
    &__images {
        max-width: 390px;
        margin: 0 auto;

        .keen-slider {
            &__slide {
                overflow: visible !important;
                padding: 15px 0;
                z-index: 1;

                &.is--active {
                    z-index: 2;
                }
            }

            @include media-breakpoint-down(md) {
                margin: 0 8%;
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }

    &__image {
        border-radius: 50%;
        height: 130px;
        opacity: 0.2;
        position: relative;
        width: 130px;
        z-index: 1;

        @include media-breakpoint-down(md) {
            height: 100px;
            width: 100px;
        }

        &.is--active {
            border: 5px solid $white;
            opacity: 1;
            position: absolute;
            left: -15px;
            right: -15px;
            width: 160px;
            height: 160px;
            top: 0;
            z-index: 2;

            @include media-breakpoint-down(md) {
                height: 133px;
                width: 133px;
            }
        }
    }

    &__title {
        color: $dark;
        font-size: 20px;
        font-weight: $font-weight-bold;
    }

    &__text {
        text-align: center;
    }

    .slider-navigation {
        border: 1px solid #866d5d;
        color: #866d5d;
        width: 53px;
        height: 53px;

        &:hover {
            background-color: #866d5d;
            color: white;
        }

        @include media-breakpoint-down(md) {
            width: 47px;
            height: 47px;
        }
    }
}