.inline-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: $white;
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;

    &.has--space-tabs {
        margin-top: 70px;
    }
}