.carousel-card {
  opacity: 0.2;
  @include media-breakpoint-down(md) {
    max-width: 500px;
    margin: 0 auto;
  }

  &.active {
    opacity: 1;
    transform: scale(1.2);

    @include media-breakpoint-down(sm) {
      transform: none;
    }
  }

  .carousel-card__number{
    font-family: $headings-font-family;
    color: $primary;
    font-size: 80px;
    left: 0;
    top: -50px;

    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  .carousel-card__border{
    height: 165px;
    width: 1px;
    background-color: $primary;
    margin-top: 35px;

    @include media-breakpoint-down(md) {
      width: 1px;
      height: 160px;
    }
  }

  .carousel-card__image{
    width: 90%;
    height: 200px;
    margin-left: 30px;


    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }

  .carousel-card__title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      font-size: 24px;
      margin-bottom: 5px;
    }
  }

  .carousel-card__text {
    font-size: 14px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
}

.slider-navigation {

}