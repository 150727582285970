.registration {
  &-image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    bottom: 0;
    left: 0;
    content: "";
    background-image: url("../../images/hero-slider.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    object-position: left center;
  }

  .outline-text {
    transform: rotate(-90deg);
    left: -571px;
    top: 630px;
    font-size: 180px;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 30px;
    @include media-breakpoint-down(md) {
      padding-left: 25px;
      padding-right: 15px;
    }
  }
}