.slider-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 1px solid $primary;
    color: $primary;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease-in-out;
    will-change: opacity;
    @include media-breakpoint-down(sm) {
        height: 40px;
        width: 40px;
    }

    &:before {
        font-family: $font-family-icon;
        font-size: 12px;
    }

    &:not(.is--disabled):hover {
        background: $primary;
        color: $white;
    }

    &.is--white {
        border: 1px solid $white;
        color: $white;

        &:not(.is--disabled):hover {
            background: $white;
            color: $dark;
        }
    }

    &.is--disabled {
        cursor: default;
        opacity: 0.5;
    }
}