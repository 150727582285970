html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
}

a {
    text-decoration: none;
    outline: none;

    &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
    }
}

:focus {
    outline: none !important;
}

.has--grey-background {
    background: #f7f7f7;
}

.has--primary-background {
    background: $primary;
}

.has--dark-grey-background {
    background: #d2d2d2;
}

// padding

.p-y-200 {
    padding: 200px 0;
}

.p-y-150 {
    padding: 150px 0;
}

.p-y-100 {
    padding: 100px 0;

}

.p-y-50 {
    padding: 50px 0;
}

.p-t-200 {
    padding-top: 100px;
}

.p-b-200 {
    padding-bottom: 100px;
}

.p-t-100 {
    padding-top: 100px;
}

.p-b-100 {
    padding-bottom: 100px;
}

.p-t-50 {
    padding-top: 50px;
}

.p-b-50 {
    padding-bottom: 50px;
}

.flag-icon {
    width: 25px;
    height: 25px;
    clip-path: circle(38% at 50% 50%);
}

.bk-spin {
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dropdown-toggle::after {
    margin-left: 5px;
    vertical-align: -5px;
    content: "\f063";
    font-family: Font Awesome 5 Free;
    font-size: 6px;
    border: none;
}

.vue-tel-input {
    border: 0 none !important;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03) !important;
    width: 100%;
}

$spacers: (10, 25, 40, 50, 75, 100, 150, 200, 250, 300);

@each $spacer in $spacers {
    .spacer-#{$spacer} {
        display: block;
        height: #{$spacer}px;
    }
}

.spacer-nav-height {
    height: 70px;
    display: block;

    @include media-breakpoint-up(md) {
        height: 90px;
    }
}

.z-5 {
    z-index: 5;
}

.z-10 {
    z-index: 10;
}