
.accordion {
    .card-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;

        .order-type-icon {
            margin-right: 15px;
            font-size: 20px;
            line-height: 20px;
        }

        .order-type-label {
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
            margin: 0;
            font-size: 16px;
            line-height: 20px;
        }


        &:not(.is--not-available) {
            &:hover, &.active {
                color: white;
                background: var(--primary);
                transition: ease-in-out 200ms all;
            }
        }

        &.is--not-available {
            color: #ccc;
        }
    }

    .card-text {
        font-size: 18px;
    }
}
