.product-badge {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  color: white;
  content: "";
  width: 300px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "\f010";
    font-size: 14px;
    color: white;
    font-family: $font-family-icon;
    padding-right: 5px;

    @include media-breakpoint-down(md) {
      font-size: 10px;
    }
  }

  @include media-breakpoint-down(md) {
    width: 200px;
    height: 40px;
    font-size: 10px;
  }


  &.has--best-rated {
    background-image: url("../../images/badge-gold.svg");
  }
}