// colors
$primary: #cc9e67;
$secondary: #866d5d;
$dark: #4b4b4b;
$red: #db5b5b;
$black: #000000;

$dark-light: #363636;
$grey-darker: #747474;
$grey-dark: #b0b0b0;
$grey: #cacaca;
$grey-light: #f7f7f7;

// fonts
$font-family-sans-serif: "Montserrat", sans-serif;
$headings-font-family: "Turbinado Bold Pro", "Montserrat", sans-serif;
$headings-font-weight: bold;
$headings-color: $dark;
$font-family-icon: 'Font Awesome 6 Free';
$font-product-item: 'Chalkduster';
$font-weight-bold: 700;
$line-height-base: 1.8;

// inputs
$input-padding-y: 20;
$input-padding-x: 10;
$input-font-size: 14;

// navbar
$navbar-height: 80px;

// footer
$footer-bg: #363636;
$sub-footer-bg: #323232;

//components

$line-height-lg: 1.4;
$line-height-sm: 1.4;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        xl: 1340px
);

//breakpoints

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

// animate css
$animationDuration: 0.3s;