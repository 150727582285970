.diet-types {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    @include media-breakpoint-down(md) {
        width: 18px;
        height: 18px;
        font-size: 10px;
    }

    &-text {
        text-transform: uppercase;
        margin: 0;
    }

    &.is--last-child {
        margin-right: 0;
    }
}