.checkout-steps {
  padding: 110px 0 40px;
  background: $dark;
  position: relative;

  &:before {
    bottom: 0;
    content: "";
    width: 100%;
    position: absolute;
    background: url("../../images/divider-white.png") no-repeat center top;
    background-size: cover;
    left: 0;
    height: 30px;
    transition: 0.2s ease-in;
    transform: rotate(-180deg);

    @include media-breakpoint-down(lg) {
      bottom: -32px;
      background-image: url("../../images/divider-header-mobile.png");
      background-size: 100% auto;
      height: 33px;
    }

    @include media-breakpoint-down(md) {
      bottom: 0px;
    }
  }

  .step-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:before {
      content: ' ';
      display: block;
      width: 93%;
      height: 2px;
      position: absolute;
      top: 20px;
      left: 30px;
      background: #C0C0C0;
    }

    .step-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      z-index: 2;


      .step-item__number {
        font-size: 46px;
        line-height: 50px;
        color: #C0C0C0;
        font-family: $headings-font-family;
        margin-bottom: 0;
        background: $dark;
        padding: 0 10px;
      }

      .step-item__title {
        color: #C0C0C0;
        font-weight: 600;
        font-size: 18px;
        line-height: 33px;
      }

      &.is--current {
        .step-item__number {
          color: white;
        }

        .step-item__title {
          color: white;
          position: relative;

          &:after {
            content: ' ';
            display: block;
            height: 2px;
            background: $primary;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .step-item_icon {
        color: $primary;
        font-size: 31px;
        position: absolute;
        top: 24px;
        right: 12px;
      }
    }
  }

  .step-wrapper-mobile {
    color: white;
    font-weight: bold;
  }
}