.main-navbar {
  background: #ffffff;
  padding-bottom: 20px;
  padding-top: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  transition: 0.2s ease-in;
  opacity: 1;
  z-index: $zindex-modal-backdrop - 10;

  @include media-breakpoint-down(md) {
    padding-bottom: 13px;
    padding-top: 13px;
  }

  &.is--open {
    @include media-breakpoint-down(md) {
      .navbar-nav {
        transform: translateX(0);
      }

      .navbar-burger-wrapper {
        .burger-label {
          margin-left: 10px;
          max-width: 200px;
          opacity: 1;
        }
      }
    }
  }

  &::before {
    bottom: -30px;
    content: "";
    width: 100%;
    position: absolute;
    background: url("../../images/divider-header.png") no-repeat center top;
    background-size: cover;
    left: 0;
    z-index: -1;
    opacity: 1;
    height: 30px;
    transition: 0.2s ease-in;

    @include media-breakpoint-down(lg) {
      bottom: -32px;
      background-image: url("../../images/divider-header-mobile.png");
      background-size: 100% auto;
      height: 33px;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: white;
    display: flex;
    justify-content: start;
  }

  .navbar-burger-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 10px;
    position: fixed;
    top: 11px;
    left: 12px;
    z-index: 1100;

    .burger-icon {
      width: 23px;
      height: 18px;

      > span {
        display: block;
        position: relative;
        height: 2px;
        width: 100%;
        background-color: $dark;
        border-radius: 1.2px;
        left: 0;
        transition: .2s;

        &:first-child {
          top: 1px;
        }

        &:nth-child(2) {
          top: 6px;
          width: 80%;
        }

        &:nth-child(3) {
          top: 11px;
        }
      }
    }

    .burger-label {
      color: $dark;
      margin-bottom: 0;
      opacity: 0;
      line-height: 18px;
      transition: all ease-in-out 0.3s;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      font-size: 12px;
      max-width: 0;
      overflow: hidden;
    }
  }

  .navbar-brand {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    img {
      height: 31px;
      width: 171px;

      @include media-breakpoint-down(lg) {
        height: 21px;
        width: 118px;
      }

    }

    &__img-claim {
      display: block;
    }

    &__img-white {
      display: none;
    }

    @include media-breakpoint-down(md) {
      margin-left: 50px;
    }
  }

  .navbar-avatar {
    height: 35px;
    width: 35px;
    color: $white;
    background: $primary;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .navbar-nav {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.8px;
    justify-content: space-around;
    width: 80%;
    white-space: nowrap;

    .nav-item {
      position: relative;

      .nav-link {
        color: $dark;
        padding: 8px 16px;

        &:before {
          background-color: $primary;
          bottom: 0;
          content: "";
          height: 2px;
          left: 16px;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          transition: all 300ms ease;
          width: 100%;
        }

        @include media-breakpoint-up(xl) {
          font-size: 14px;
          padding: 6px 14px;

          &:before {
            left: 14px;
          }
        }

        @include media-breakpoint-down(xl) {
          font-size: 11px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 11px;
          padding: 6px 5px;

          &:before {
            left: 5px;
          }
        }

        &.active,
        &:hover {
          &:before {
            max-width: 44px;
            opacity: 1;
          }
        }
      }

      &.is--call-to-action {
        border: 1px solid $primary;
        border-radius: 30px;
        color: $primary !important;
        transition: 0.3s;

        &:hover {
          background-color: $primary;
        }

        .nav-link {
          color: $primary;

          &:hover {
            color: white;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      height: 100vh;
      width: 80%;
      position: fixed;
      background-color: white;
      left: 0;
      top: 0;
      z-index: 1090;
      transform: translateX(-110%);
      transition: transform ease-in-out 300ms;
      will-change: transform;
      justify-content: flex-start;
      padding-top: 100px;

      .is--call-to-action {
        display: none;
      }

      .call-to-action {
        background-color: #f7f7f7;
        padding: 60px 0 60px 30px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 140px;


        .call-to-action__label {
          font-size: 14px;
          letter-spacing: 2.4px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        .call-to-action__title {
          font-family: $headings-font-family;
          font-weight: 500;
          font-size: 94px;
          text-transform: initial;
          line-height: 60px;
        }
      }

      .nav-login {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .login-wrapper {
          color: white;
          background-color: $dark;
          height: 140px;

          .login-text {
            font-weight: 500;
            letter-spacing: 0.47px;
            font-size: 14px;
            margin-bottom: 0;
          }

          .login-icon {
            font-size: 24px;
            margin-bottom: 10px;
          }

        }

        .registration-wrapper {
          color: white;
          background-color: #5f5f5f;
          height: 140px;

          .register-text {
            font-weight: 500;
            letter-spacing: 0.47px;
            font-size: 14px;
            margin-bottom: 0;
          }

          .register-icon {
            font-size: 24px;
            margin-bottom: 10px;
          }
        }
      }

      .nav-item {
        padding-left: 45px;

        .nav-link {
          font-size: 25px;
          font-weight: normal;
          line-height: 45px;
          text-transform: none;

          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }

  .navbar-icon-wrapper {
    font-size: 19px;
    line-height: 19px;
    margin-right: 80px;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(lg) {
      margin-right: 40px;
    }

    @include media-breakpoint-down(md) {
      margin-right: 30px;
    }

    .navbar-icon {
      color: $dark;
      margin-right: 20px;
      cursor: pointer;
      position: relative;
      transition: color 300ms ease;

      @include media-breakpoint-down(lg) {
        margin-right: 20px;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }

      &:hover {
        color: $primary;
      }

      &__badge {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #db5b5b;
        color: white;
        border-radius: 20px;
        font-size: 10px;
        font-weight: bold;
        top: -10px;
        right: -12px;
      }
    }
  }

  .navbar-cart {
    position: fixed;
    top: 0;
    right: 0;
    width: 130px;
    height: 130px;
    background-color: $primary;
    border-radius: 130px 0 130px 130px;
    margin-top: -18px;
    margin-right: -18px;
    flex-direction: column;
    cursor: pointer;
    z-index: $zindex-fixed + 40;

    @include media-breakpoint-down(lg) {
      width: 100px;
      height: 100px;
      border-radius: 100px 0 100px 100px;
    }

    @include media-breakpoint-down(md) {
      width: 80px;
      height: 80px;
      margin-top: -10px;
      margin-right: -15px;
    }

    .navbar-cart__icon {
      font-size: 30px;
      color: white;

      @include media-breakpoint-down(lg) {
        font-size: 20px;
        margin-top: 8px;
      }

      @include media-breakpoint-down(md) {
        font-size: 25px;
        margin-right: 10px;
        margin-top: 10px;
      }

      &-badge {
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #db5b5b;
        color: white;
        border-radius: 20px;
        font-size: 10px;
        font-weight: bold;
        top: -6px;
        right: -8px;

        @include media-breakpoint-down(md) {
          top: -1px;
          right: -2px;
        }
      }
    }
  }

  .navbar-cart > p {
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.40px;
    margin: 0;
    padding-top: 5px;

    @include media-breakpoint-down(lg) {
      font-size: 10px;
      padding-top: 2px;
    }
  }


  &.is--transparent {
    background: none;

    &:before {
      opacity: 0;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: white;
        }

        &.is--call-to-action > a {
          color: $primary;
        }
      }
    }

    .navbar-icon-wrapper {
      .navbar-icon {
        color: white;
        position: relative;

        &:hover {
          color: $primary;
        }
      }
    }

    .navbar-brand {
      &__img-claim {
        display: none;
      }

      &__img-white {
        display: block;
      }
    }

    .burger-icon {
      > span {
        background-color: $white;
      }
    }
  }

  &.is--open {
    .burger-icon {
      > span {
        background-color: $dark;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $dark;

          &:before {
            bottom: auto;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 35px;
          }
        }
      }
    }
  }
}
