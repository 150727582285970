.input-group {
  .input-group-prepend {
    .btn {
      padding: 0;
      background-color: #e4e4e4;
      border: none;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
      margin-bottom: 0px;
      width: 35px;
      height: 35px;

      .input-group-icon {
        color: $dark;
        font-size: 12px;
      }
    }
  }
}

.quantity-input {
  width: auto !important;
  max-width: 110px;
  margin-right: 10px;
  padding: 10px 0;

  .btn {
    border: none;
    background: #ececec;
    transition: background ease-in-out 200ms;
    border-radius: 35px !important;
    width: 35px;
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    font-size: 14px;
    line-height: 1em;
    color: #7e7e7e;

    @include media-breakpoint-down(md) {
      border-radius: 30px !important;
      width: 30px;
      height: 35px;
    }

    &:hover, &:focus {
      background: #ccc;
    }

    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }

  .form-control {
    height: 35px;
    width: 80px;
    background: transparent;
    border: none;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-left: -20px;
    margin-right: -20px;
    font-size: 14px;
    box-shadow: none;
    -moz-appearance: textfield;
    font-weight: 600;

    @include media-breakpoint-down(md) {
      width: 75px;
      height: 35px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus, &:hover {
      box-shadow: none;
      outline: none;
      z-index: 1;
    }

  }
}
