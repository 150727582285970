.contact {
    padding-bottom: 100px;

    @include media-breakpoint-down(md) {
        padding-bottom: 50px;
    }

    .contact-header {
        color: #a4a4a4;
        font-weight: $font-weight-bold;
        font-size: 14px;
        letter-spacing: 2.67px;
        text-transform: uppercase;
    }

    .contact-logo {
        width: auto;
        height: 35px;
        margin-bottom: 40px;
        margin-top: 20px;

        @include media-breakpoint-down(md) {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }

    .contact-title {
        font-weight: $font-weight-bold;
    }

    .contact-place > p {
        margin-bottom: 0;
    }

    .contact-place {
        margin-bottom: 20px;
    }

    .contact-wrapper {
        .contact-phone > p {
            margin-bottom: 0;
            margin-right: 10px;
        }

        .contact-phone > a {
            color: $dark;
        }

        .contact-mail > p {
            margin-bottom: 0;
            margin-right: 10px;
        }

        .contact-mail > a {
            color: $dark;
        }
    }

    .form-group {
        .form-control {
            height: 60px;
            border: solid 1px #F5F5F5;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
        }

        .textarea {
            height: 180px;

            &::placeholder {
                color: #cacaca;
            }
        }
    }
}

.company-grid {
    padding: 200px 0;

    @include media-breakpoint-down(sm) {
        padding: 100px 0;
    }

    .outline-text {
        transform: rotate(-90deg);
        left: -350px;
        top: 50%;
        font-size: 200px;

        @include media-breakpoint-down(md) {
            transform: rotate(0);
            top: 50px;
            left: 0;
            font-size: 80px;
        }
    }
}